import React, { useContext } from 'react'
import Bottom from '../../bottom/Bottom'
import { Form } from 'react-final-form'
import { StepperContext } from '../../StepperContext'
import RadioGroup, { RadioOption } from '../../../../components/field/radio/RadioGroup'
import Input from '../../../../components/field/input/Input'
import PristineListener from '../../PristineListener'
import { RIV_STEP_TYPE } from '../../../../utils/enums'
import './client-movement.css'
import TranslatedMessage from '../../../translation/TranslatedMessage'
import { MovementContext } from '../../../../movement/MovementContext'

const ClientMovement = () => {
	const { currentStepInfo, rivInfo } = useContext(StepperContext)
	const { movements } = useContext(MovementContext)

	const saveValues = (values: ClientMovementForm) => {
		currentStepInfo.data = { ...currentStepInfo.data, values }
	}

	const movementOptions: RadioOption[] = movements
		.filter(m => !m.disabled)
		.map((m, i) => ({
			label: <TranslatedMessage id={m.label} defaultMessage={`Movement option #${i}`} description={m.code} />,
			value: m.code
		}))

	const movementCodeWithComment: String[] = movements
		.filter(m => m.hasComment)
		.map(m => m.code)

	const validate = (values: ClientMovementForm) => {
		const errors: any = {}
		if (!values.name) {
			errors.name = 'errors.form.required'
		}
		if (!values.type) {
			errors.type = 'errors.form.required'
		}

		if (movementCodeWithComment.includes(values.type)) {
			if (!values.movementComment) {
				errors.movementComment = 'errors.form.required'
			}
		}
		return errors
	}

	return (
		<Form
			onSubmit={saveValues}
			validate={validate}
			initialValues={{ ...currentStepInfo.data.values }}
			render={({ handleSubmit, invalid, values, pristine }) => {
				const movementSelected: Movement | undefined = !!values.type ? movements.find(m => m.code === values.type) : undefined

				return (
					<>
						<PristineListener pristine={pristine} />
						<form onSubmit={handleSubmit} className="layout" autoComplete="off">
							<div className="left">
								<Input
									name="name"
									label="client_movement.inputs.client_name"
									type="text"
									disabled={rivInfo.type === RIV_STEP_TYPE.BACK}
								/>
								{rivInfo.type === RIV_STEP_TYPE.BACK ?
									<div className="step-back">
										<label><TranslatedMessage id="client_movement.radios.labels.movement_type" defaultMessage="Label" description="Input label" /></label>
										<div className="value">
											<p>
												<TranslatedMessage id={movementSelected?.label} defaultMessage="Movement option selected" description={movementSelected?.code || ''} />
											</p>
										</div>
									</div>
									:
									<RadioGroup
										name="type"
										direction="column"
										options={movementOptions}
										label="client_movement.radios.labels.movement_type"
									/>
								}
								{(movementCodeWithComment.includes(values.type)) && <Input
									disabled={rivInfo.type === RIV_STEP_TYPE.BACK}
									name="movementComment"
									label={movementSelected?.commentLabel}
									type={movementSelected?.commentType}
								/>}
							</div>
						</form>
						<Bottom
							disabled={invalid || rivInfo.type === RIV_STEP_TYPE.BACK}
							disabledSave={invalid || rivInfo.type === RIV_STEP_TYPE.BACK}
							onNext={() => saveValues(values)}
							onSave={() => saveValues(values)}
						/>
					</>
				)
			}}
		/>
	)
}

export default ClientMovement
