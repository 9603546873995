import { Grid } from '@material-ui/core'
import { InfoOutlined } from '@material-ui/icons'
import React, { useContext } from 'react'
import { Form } from 'react-final-form'
import { useHistory } from 'react-router'
import { LargeButton } from '../../components/buttons/Buttons'
import Header from '../../components/header/Header'
import RadioGroup from '../../components/field/radio/RadioGroup'
import { ZONE_CODE } from '../../utils/enums'
import { LOCAL_STORAGE_ITEM } from '../../utils/constants'
import { RivIntlContext } from '../../config/messages/RivIntlContext'
import { StepperContext } from '../stepper/StepperContext'
import TranslatedMessage from '../translation/TranslatedMessage'
import { TranslationContext } from '../translation/TranslationContext'
import { resetRivSteps } from '../../services/save'

type PreferencesForm = {
	language: string,
	zone: string
}

const PreferencesPage = () => {
	const history = useHistory()
	const { changeLanguage } = useContext(RivIntlContext)
	const { setActiveStep, stepsInfo } = useContext(StepperContext)
	const { languages } = useContext(TranslationContext)

	const handlePreferences = (values: PreferencesForm) => {
		localStorage.setItem(LOCAL_STORAGE_ITEM.LANGUAGE, values.language)
		changeLanguage(values.language)

		localStorage.setItem(LOCAL_STORAGE_ITEM.ZONE, values.zone)
		if (setActiveStep) {
			resetRivSteps(stepsInfo)
			setActiveStep(0)
		}

		history.push('/', { forceRefresh: true })
	}

	const validate = (values: PreferencesForm) => {
		const errors: any = {}
		if (!values.language) {
			errors.language = 'errors.form.required'
		}
		if (!values.zone) {
			errors.zone = 'errors.form.required'
		}
		return errors
	}

	return (
		<Grid item>
			<Header>
				<InfoOutlined fontSize="large" />
				<span>
                    <TranslatedMessage id="preferences.title" defaultMessage="Vos préférences" description="Page Title" />
                </span>
				<div className="version">
					<span>V{process.env.REACT_APP_VERSION}</span>
				</div>
			</Header>
			<Form
				onSubmit={handlePreferences}
				validate={validate}
				initialValues={{
					zone: localStorage.getItem(LOCAL_STORAGE_ITEM.ZONE),
					language: localStorage.getItem(LOCAL_STORAGE_ITEM.LANGUAGE)
				}}
				render={({ handleSubmit, invalid }) => (
					<form onSubmit={handleSubmit} className="flex-col pl-4 pr-4 pt-4 pb-4" autoComplete="off">
						<RadioGroup
							name="language"
							direction="column"
							options={languages.map(lang => ({
								label: <TranslatedMessage id={`enums.language.${lang.languageCode}`} defaultMessage={lang.languageCode} description={lang.languageCode} />,
								value: lang.languageCode,
								img: lang.flagSrc
							}))
							}
							label="preferences.language"
						/>
						<RadioGroup
							name="zone"
							direction="column"
							options={Object.values(ZONE_CODE)
								.map(zone => ({
									label: <TranslatedMessage id={`enums.zone.${zone}`} defaultMessage={zone} description={zone} />,
									value: zone,
									img: `img/country/${zone.toLowerCase()}.png`
								}))
							}
							label="preferences.zone"
						/>
						<LargeButton type="submit" disabled={invalid}>
							<TranslatedMessage id="cgu.validate" defaultMessage="Valider" description="Validate" />
						</LargeButton>
					</form>
				)}
			/>
		</Grid>
	)
}

export default PreferencesPage
