import { CircularProgress, Typography } from '@material-ui/core'
import { Search } from '@material-ui/icons'
import React, { useContext, useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { MediumButton } from '../../../../components/buttons/Buttons'
import Input from '../../../../components/field/input/Input'
import RadioGroup, { RadioOption } from '../../../../components/field/radio/RadioGroup'
import { deleteData, getSearchVehicles } from '../../../../services/api'
import { CALL_STATE, RIV_STEP_TYPE, STEP_KEY, STEP_STATE, VEHICLE_SEARCH_TYPE } from '../../../../utils/enums'
import LoadSaveDialog from '../../../dialog/load-save/LoadSaveDialog'
import ModelSelectionDialog from '../../../dialog/model-selection/ModelSelectionDialog'
import ModifyDialog from '../../../dialog/modify/ModifyDialog'
import SimpleWarningDialog from '../../../dialog/warning/SimpleWarningDialog'
import Bottom from '../../bottom/Bottom'
import { StepperContext } from '../../StepperContext'
import './search-vehicle.css'
import ActionWarningDialog from '../../../dialog/warning/ActionWarningDialog'
import { TranslationContext } from '../../../translation/TranslationContext'
import TranslatedMessage from '../../../translation/TranslatedMessage'
import { loadVehicleStateFromPreviousBackStep } from '../../../../services/save'

const SearchVehicle = () => {
	const [selectedModel, setSelectedModel] = useState<AssetModel>()
	const [foundVehicles, setFoundVehicles] = useState<Vehicle[]>()
	const [selectedVehicle, setSelectedVehicle] = useState<Vehicle>()
	const [callState, setCallState] = useState<CALL_STATE>(CALL_STATE.SUCCESS)
	const [openSelectDialog, setOpenSelectDialog] = useState(false)
	const [openModifyDialog, setOpenModifyDialog] = useState(false)
	const [openLoadSaveDialog, setOpenLoadSaveDialog] = useState(false)
	const [openMediaMissingDialog, setOpenMediaMissingDialog] = useState(false)
	const [openIsSendingDialog, setOpenIsSendingDialog] = useState(false)
	const { currentStepInfo, rivInfo, stepsInfo, setTools, uploadFilesInfos, uploadRivInfos } = useContext(StepperContext)
	const { translations } = useContext(TranslationContext)

	useEffect(() => {
		// Load saved data
		if (currentStepInfo.data.foundVehicles) {
			setFoundVehicles(currentStepInfo.data.foundVehicles)
			setSelectedVehicle(currentStepInfo.data.selectedVehicle)
		}
		if (currentStepInfo.data.selectedModel) {
			setSelectedModel(currentStepInfo.data.selectedModel)
		}
	}, [currentStepInfo])

	const resetData = () => {
		setFoundVehicles(undefined)
		setSelectedVehicle(undefined)
		setSelectedModel(undefined)
		setCallState(CALL_STATE.SUCCESS)
		rivInfo.draft = false
		rivInfo.type = RIV_STEP_TYPE.GO

		delete currentStepInfo.data.selectedModel
	}

	const handleSearch = async (values: SearchForm) => {
		try {
			resetData()
			const searchResponse = await getSearchVehicles(values)
			setFoundVehicles(searchResponse)
			currentStepInfo.data = { ...currentStepInfo.data, values, foundVehicles: searchResponse }
			if (searchResponse.length === 1) {
				loadInfoVehicule(searchResponse[0])
			}
		} catch (error) {
			setCallState(CALL_STATE.ERROR)
		}
	}

	const handleNext = () => {
		currentStepInfo.data = { ...currentStepInfo.data, selectedModel, foundVehicles, selectedVehicle }

		// On initialise un nouvel aller avec les dégâts du précédent retour dans le cas du même modèle
		if (selectedVehicle != null && selectedModel != null && !selectedVehicle.savedRivInfo
			&& selectedVehicle.previousBackStepModelId === selectedModel.id && selectedVehicle.previousBackStepDamages.length > 0) {

			loadVehicleStateFromPreviousBackStep(selectedModel, selectedVehicle.previousBackStepDamages, stepsInfo.find(step => step.key === STEP_KEY.OUTSIDE_STATE))
		}
	}

	const handleLoad = (vehicle: Vehicle) => {
		rivInfo.zoneCode = vehicle.zone
		const fileUpload = uploadFilesInfos.filter((fileInfo) => fileInfo.parcNumber === vehicle.parcNumber && fileInfo.plateNumber === vehicle.vehicleRegistration)
		const rivUpload = uploadRivInfos.find((rivInfo) => rivInfo.parcNumber === vehicle.parcNumber && rivInfo.plateNumber === vehicle.vehicleRegistration)

		if (fileUpload.some((fileInfo) => fileInfo.uploadState !== STEP_STATE.DONE) || (!!rivUpload && rivUpload.uploadState !== STEP_STATE.DONE)) {
			setOpenIsSendingDialog(true)
		} else if (vehicle.savedRivInfo) {
			if (vehicle.savedRivInfo.rivStep.draft) {
				const mediaMissing = vehicle.savedRivInfo.rivMedias.some((media) => media.name === null)
				if (mediaMissing) {
					setOpenMediaMissingDialog(true)
				} else {
					setOpenLoadSaveDialog(true)
				}
			} else if (vehicle.savedRivInfo.rivStep.type === RIV_STEP_TYPE.GO) {
				rivInfo.type = RIV_STEP_TYPE.BACK
				currentStepInfo.data = { ...currentStepInfo.data, selectedVehicle: vehicle, selectedModel: vehicle.assetModels.find((model) => model.id === vehicle.savedRivInfo?.riv.idModel) }
				currentStepInfo.state = STEP_STATE.DONE
				setSelectedModel(currentStepInfo.data.selectedModel)
				stepsInfo.forEach((step) =>
					step.loadSaveData(
						vehicle.savedRivInfo!,
						step,
						rivInfo,
						vehicle.tools,
						vehicle.assetModels.find((model) => model.id === vehicle.savedRivInfo?.riv.idModel)
					)
				)
			}
		}
	}

	const handleSelectVehicle = (index: string) => {
		if (!foundVehicles) {
			return
		}
		loadInfoVehicule(foundVehicles[Number(index)])
	}

	const loadInfoVehicule = (vehicle: Vehicle) => {
		setSelectedVehicle(vehicle)
		if (setTools) {
			setTools(vehicle.tools)
		}
		if (vehicle.assetModels.length === 1) {
			setTimeout(() => {
				setSelectedModel(vehicle.assetModels[0])
				currentStepInfo.data.selectedModel = vehicle.assetModels[0]
			}, 50)
		} else {
			const previousModel = vehicle.previousBackStepModelId && vehicle.assetModels.find(v => v.id === vehicle.previousBackStepModelId)
			if (previousModel) {
				setSelectedModel(previousModel)
				currentStepInfo.data.selectedModel = previousModel
			} else {
				setSelectedModel(undefined)
				delete currentStepInfo.data.selectedModel
			}
		}
		handleLoad(vehicle)
	}

	const handleDelete = async () => {
		if (selectedVehicle && selectedVehicle.savedRivInfo && selectedVehicle.savedRivInfo.riv) {
			await deleteData(selectedVehicle.savedRivInfo.riv.id)
			delete selectedVehicle.savedRivInfo
			rivInfo.draft = false

			resetData()
		}
	}

	const validate = (values: SearchForm) => {
		const errors: any = {}
		if (!values.type) {
			errors.type = 'errors.form.required'
		}
		if (!values.query) {
			errors.query = 'errors.form.required'
		}
		return errors
	}

	const searchOptions: RadioOption[] = [
		{
			label: <TranslatedMessage id="search_vehicle.radios.search_by.parc_number" defaultMessage="Numéro de parc" description="Park query" />,
			value: 'PARC_NUMBER'
		},
		{
			label: <TranslatedMessage id="search_vehicle.radios.search_by.numberplate" defaultMessage="Immatriculation" description="Numberplate" />,
			value: 'VEHICLE_REGISTRATION'
		}
	]

	return (
		<>
			<div className="layout search-vehicle">
				<div className="left">
					<Form
						onSubmit={handleSearch}
						validate={validate}
						initialValues={{ ...currentStepInfo.data.values }}
						render={({ handleSubmit, invalid, values }) => (
							<form onSubmit={handleSubmit} className="flex-col" autoComplete="off">
								<RadioGroup
									name="type"
									direction="row"
									disabled={currentStepInfo.state === STEP_STATE.DONE}
									options={searchOptions}
									label="search_vehicle.radios.labels.search_by"
									onValueChange={resetData}
								/>
								{values.type && (
									<Input
										disabled={currentStepInfo.state === STEP_STATE.DONE}
										name="query"
										label={`search_vehicle.inputs.labels.${values.type === searchOptions[0].value ? 'parc' : 'numberplate'}`}
										type={values.type === VEHICLE_SEARCH_TYPE.PARC_NUMBER ? 'number' : 'text'}
									>
										<MediumButton type="submit" disabled={invalid || currentStepInfo.state === STEP_STATE.DONE}>
											<Search fontSize="small" />
										</MediumButton>
									</Input>
								)}
							</form>
						)}
					/>
					{callState === CALL_STATE.LOADING && (
						<div className="flex center">
							<CircularProgress color="primary" />
						</div>
					)}
					{callState === CALL_STATE.ERROR && (
						<p className="error network">
							<TranslatedMessage id="errors.network" defaultMessage="Non connecté à Internet" description="No network" />
						</p>
					)}
					{foundVehicles && foundVehicles.length === 0 && (
						<p className="network">
							<TranslatedMessage id="search_vehicle.no_match" defaultMessage="Aucun résultat trouvé" description="No matching result found" />
						</p>
					)}
					{foundVehicles && foundVehicles.length > 1 && (
						<Form
							onSubmit={() => {
							}}
							initialValues={{
								vehicle: `${foundVehicles.findIndex(
									(vehicle) => vehicle.vehicleRegistration === selectedVehicle?.vehicleRegistration && vehicle.parcNumber === selectedVehicle?.parcNumber
								)}`
							}}
							render={() => (
								<form autoComplete="off">
									<RadioGroup
										name="vehicle"
										direction="row"
										options={foundVehicles.map((vehicle, i) => ({
											label: `${vehicle.parcNumber} ${vehicle.vehicleRegistration ? `/ ${vehicle.vehicleRegistration}` : ''}`,
											value: i.toString()
										}))}
										disabled={currentStepInfo.state === STEP_STATE.DONE}
										label="search_vehicle.radios.labels.found_two"
										onValueChange={handleSelectVehicle}
									/>
								</form>
							)}
						/>
					)}
				</div>
				<div className="right">
					{foundVehicles && foundVehicles.length > 0 && selectedVehicle !== undefined && (
						<div className="box flex-col">
							<Typography variant="h2" component="h2">
								<TranslatedMessage id={`search_vehicle.info.title.${rivInfo.type === RIV_STEP_TYPE.BACK ? 'back' : 'go'}`} defaultMessage="Etablissement d’un RIV" description="Title" />
							</Typography>
							<div className="info">
								<Typography variant="caption" component="span">
									<TranslatedMessage id="search_vehicle.info.parc_number" defaultMessage="N° de parc" description="Title" />
								</Typography>
								<Typography variant="body1" component="p">
									{selectedVehicle.parcNumber}
								</Typography>
							</div>
							<div className="info">
								<Typography variant="caption" component="span">
									<TranslatedMessage id="search_vehicle.info.numberplate" defaultMessage="Immatriculation" description="Title" />
								</Typography>
								<Typography variant="body1" component="p">
									{selectedVehicle.vehicleRegistration ?? '-'}
								</Typography>
							</div>
							<div className="info">
								<Typography variant="caption" component="span">
									<TranslatedMessage id="search_vehicle.info.agence" defaultMessage="Agence" description="Title" />
								</Typography>
								<Typography variant="body1" component="p">
									{selectedVehicle.agence}
								</Typography>
							</div>
							<div className="info">
								<Typography variant="caption" component="span">
									<TranslatedMessage id="search_vehicle.info.category" defaultMessage="Catégorie" description="Title" />
								</Typography>
								<Typography variant="body1" component="p">
									{translations[selectedVehicle.assetCategory.label]}
								</Typography>
							</div>
							<div className="info">
								<Typography variant="caption" component="span">
									<TranslatedMessage id="search_vehicle.info.model" defaultMessage="Modèle" description="Title" />
								</Typography>
								{selectedVehicle.assetModels.length === 1 && (
									<img src={selectedVehicle.assetModels[0].urlFace1} className="faces" alt="face" />
								)}
								{selectedVehicle.assetModels.length > 1 &&
								(selectedModel ? (
									<div>
										<img src={selectedModel.urlFace1} className="faces mb-2" alt="face" />
										{rivInfo.type === RIV_STEP_TYPE.GO && (
											<MediumButton disabled={currentStepInfo.state === STEP_STATE.DONE} onClick={() => setOpenSelectDialog(true)}>
												<TranslatedMessage id="search_vehicle.modify_model" defaultMessage="Sélectionnez un modèle" description="Title" />
											</MediumButton>
										)}
									</div>
								) : (
									<MediumButton disabled={currentStepInfo.state === STEP_STATE.DONE} onClick={() => setOpenSelectDialog(true)}>
										<TranslatedMessage id="search_vehicle.button_model" defaultMessage="Sélectionnez un modèle" description="Title" />
									</MediumButton>
								))}
							</div>
							<ModelSelectionDialog open={openSelectDialog} setOpen={setOpenSelectDialog} assetModels={selectedVehicle.assetModels} onChoose={(val) => setSelectedModel(val)} />
						</div>
					)}
				</div>
			</div>
			<ModifyDialog open={openModifyDialog} setOpen={setOpenModifyDialog} onBack={() => rivInfo.type === RIV_STEP_TYPE.BACK && resetData()} />
			<LoadSaveDialog open={openLoadSaveDialog} setOpen={setOpenLoadSaveDialog} vehicle={selectedVehicle} onLeave={resetData} />
			<ActionWarningDialog open={openMediaMissingDialog} setOpen={setOpenMediaMissingDialog} onConfirm={handleDelete} contentLabel="search_vehicle.dialog.media_missing" />
			<SimpleWarningDialog open={openIsSendingDialog} setOpen={setOpenIsSendingDialog} onLeave={resetData} contentLabel="search_vehicle.dialog.is_sending" />
			<Bottom disabled={!selectedModel} onNext={() => rivInfo.type === RIV_STEP_TYPE.GO && handleNext()} onModify={() => setOpenModifyDialog(true)} />
		</>
	)
}

export default SearchVehicle
