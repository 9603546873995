import { DialogProps, Typography } from '@material-ui/core'
import React, { useContext, useState } from 'react'
import { Form } from 'react-final-form'
import { LargeButton } from '../../../components/buttons/Buttons'
import RadioGroup from '../../../components/field/radio/RadioGroup'
import { StepperContext } from '../../stepper/StepperContext'
import { RivDialog, RivDialogActions } from '../DialogStyles'
import './model-selection-dialog.css'
import TranslatedMessage from '../../translation/TranslatedMessage'

type Props = {
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
	assetModels: AssetModel[]
	onChoose: (value: AssetModel) => void
} & DialogProps

type ModelForm = {
	model: string
}

const ModelSelectionDialog = ({ open, setOpen, assetModels, onChoose, ...rest }: Props) => {
	const [selectedModel, setSelectedModel] = useState<AssetModel>()
	const { currentStepInfo } = useContext(StepperContext)

	const loadData = () => {
		if (currentStepInfo.data.selectedModel) {
			setSelectedModel(currentStepInfo.data.selectedModel)
		}
	}

	const handleClose = () => {
		setOpen(false)
	}

	const getImgElem = (filename: string) => <img src={filename} className="faces" alt={filename} />

	const validate = (values: ModelForm) => {
		const errors: any = {}
		if (!values.model) {
			errors.model = 'errors.form.required'
		}
		return errors
	}

	const handleSubmit = (values: ModelForm) => {
		const model = assetModels.find((model) => model.id === values.model)
		if (!model) {
			return
		}
		currentStepInfo.data.selectedModel = model
		onChoose(model)
		handleClose()
	}

	return (
		<RivDialog {...rest} label="search_vehicle.dialog.model_selection.title" className="model-selection-dialog" onClose={handleClose} open={open} onEnter={loadData}>
			<Typography variant="body1" component="p">
				<TranslatedMessage id="search_vehicle.dialog.model_selection.select" defaultMessage="Sélectionnez un modèle de véhicule pour effectuer le RIV" description="dialog content" />
			</Typography>
			<Form
				onSubmit={handleSubmit}
				validate={validate}
				initialValues={{ model: selectedModel?.id }}
				render={({ handleSubmit, invalid }) => (
					<form onSubmit={handleSubmit} className="flex-col" autoComplete="off">
						<RadioGroup name="model" direction="column" options={assetModels.map((model) => ({ value: model.id, label: getImgElem(model.urlFace1) }))} />
						<RivDialogActions>
							<LargeButton type="submit" className="full-width" disabled={invalid}>
								<TranslatedMessage id="search_vehicle.dialog.model_selection.next" defaultMessage="Continuer" description="Next button" />
							</LargeButton>
						</RivDialogActions>
					</form>
				)}
			/>
		</RivDialog>
	)
}

export default ModelSelectionDialog
