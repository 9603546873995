import { DialogProps, Typography } from '@material-ui/core'
import React, { useContext } from 'react'
import { Form } from 'react-final-form'
import { useIntl } from 'react-intl'
import { LargeButton } from '../../../components/buttons/Buttons'
import RadioGroup, { RadioOption } from '../../../components/field/radio/RadioGroup'
import { deleteData } from '../../../services/api'
import { BOOLEAN, RIV_STEP_TYPE, STEP_STATE } from '../../../utils/enums'
import { StepperContext } from '../../stepper/StepperContext'
import { RivDialog, RivDialogActions } from '../DialogStyles'
import TranslatedMessage from '../../translation/TranslatedMessage'

type Props = {
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
	vehicle?: Vehicle
	onLeave: () => void
} & DialogProps

type LoadForm = {
	load: string
}

const LoadSaveDialog = ({ open, setOpen, vehicle, onLeave }: Props) => {
	const { rivInfo, stepsInfo, refresh, currentStepInfo, setActiveStep } = useContext(StepperContext)
	const intl = useIntl()

	const handleClose = () => {
		onLeave()
		setOpen(false)
	}

	const handleDelete = async () => {
		if (vehicle && vehicle.savedRivInfo && vehicle.savedRivInfo.riv) {
			await deleteData(vehicle.savedRivInfo.riv.id)
			delete vehicle.savedRivInfo
			rivInfo.draft = false
			handleClose()
		}
	}

	const handleLoadSave = () => {
		if (vehicle && vehicle.savedRivInfo) {
			rivInfo.draft = true
			rivInfo.type = vehicle.savedRivInfo.rivStep.type
			currentStepInfo.data = { ...currentStepInfo.data, selectedVehicle: vehicle, selectedModel: vehicle.assetModels.find((model) => model.id === vehicle.savedRivInfo?.riv.idModel) }
			currentStepInfo.state = STEP_STATE.DONE
			stepsInfo.forEach((step) => step.loadSaveData(vehicle.savedRivInfo!, step, rivInfo, vehicle.tools, vehicle.assetModels.find((model) => model.id === vehicle.savedRivInfo?.riv.idModel)))
			const inProgressStepIndex = stepsInfo.findIndex((stepInfo) => stepInfo.state === STEP_STATE.TODO)
			if (inProgressStepIndex !== -1 && setActiveStep) {
				stepsInfo[inProgressStepIndex].state = STEP_STATE.IN_PROGRESS
				setActiveStep(inProgressStepIndex)
			}
			refresh()
			setOpen(false)
		}
	}

	const getDateTime = () => {
		if (vehicle && vehicle.savedRivInfo) {
			const datetime = new Date(`${vehicle.savedRivInfo.rivStep.modificationDate}Z`)
			return { date: intl.formatDate(datetime), time: intl.formatTime(datetime) }
		}
		return
	}

	const handleSubmit = (values: LoadForm) => {
		if (values.load === BOOLEAN.TRUE) {
			handleLoadSave()
		} else {
			handleDelete()
		}
	}

	const validate = (values: LoadForm) => {
		const errors: any = {}
		if (!values.load) {
			errors.load = 'errors.form.required'
		}
		return errors
	}

	const loadRivOptions: RadioOption[] = [
		{
		    label: <TranslatedMessage id="search_vehicle.dialog.load_save.load" defaultMessage="Reprendre le RIV pré-enregistré" description="Load Riv" />,
			value: BOOLEAN.TRUE
		},
		{
            label: <TranslatedMessage id="search_vehicle.dialog.load_save.delete" defaultMessage="Effectuer une nouvelle saisie" description="Delete Riv" />,
			value: BOOLEAN.FALSE
		}
	]

	return (
		<RivDialog onClose={handleClose} open={open} label={`search_vehicle.dialog.load_save.title.${vehicle?.savedRivInfo?.rivStep.type === RIV_STEP_TYPE.GO ? 'go' : 'back'}`} disableBackdropClick>
			<Typography variant="body1" component="p">
				<TranslatedMessage
					id="search_vehicle.dialog.load_save.content"
					values={getDateTime()}
					defaultMessage="Un RIV a déjà été pré-enregistré pour ce véhicule"
					description="Saved riv found"
				/>
			</Typography>
			<Form
				onSubmit={handleSubmit}
				validate={validate}
				render={({ handleSubmit, invalid }) => (
					<form onSubmit={handleSubmit} className="flex-col" autoComplete="off">
						<RadioGroup name="load" direction="column" options={loadRivOptions} label="search_vehicle.dialog.load_save.label" />
						<Typography variant="body1" component="p">
							<TranslatedMessage
								id="search_vehicle.dialog.load_save.warning"
								defaultMessage="Attention, dans le cas d’une nouvelle saisie les données pré-enregistrées seront perdues."
								description="Warning"
							/>
						</Typography>
						<RivDialogActions>
							<LargeButton type="submit" disabled={invalid} className="full-width">
								<TranslatedMessage id="search_vehicle.dialog.model_selection.next" defaultMessage="Continuer" description="Next" />
							</LargeButton>
						</RivDialogActions>
					</form>
				)}
			/>
		</RivDialog>
	)
}

export default LoadSaveDialog
