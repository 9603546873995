import React, { useEffect, useRef, useState } from 'react'
import SignaturePad from 'react-signature-canvas'
import './signature.css'
import { FieldValidator } from 'final-form'
import { Field } from 'react-final-form'
import { ResetButton } from '../../buttons/Buttons'
import TranslatedMessage from '../../../modules/translation/TranslatedMessage'

type Props = {
	label?: string
	id?: string
	name: string
	disabled?: boolean
	validate?: FieldValidator<any> | undefined
} & React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>

const Signature = ({ label, id, name, validate, disabled, ...input }: Props) => {
	const [onFocus, setOnFocus] = useState<boolean>(false)
	const sigPad = useRef<SignaturePad>(null)

	useEffect(() => {
		if (input.value && String(input.value) && String(input.value).length) {
			sigPad && sigPad.current && sigPad.current.fromDataURL(String(input.value))
		}
	})
	useEffect(() => {
		if (sigPad && sigPad.current) {
			disabled ? sigPad && sigPad.current.off() : sigPad && sigPad.current.on()
		}
	}, [disabled])

	return (
		<Field name={name} validate={validate}>
			{({ input, meta }) => (
				<div className={`signature ${meta.error && meta.touched ? 'error' : ''}`}>
					{label && (
						<div className="flex">
							<label htmlFor={id}>
								<TranslatedMessage id={label} defaultMessage="Label" description="Input label" />
							</label>
							<ResetButton size="small" onClick={() => {
								input.onChange(undefined)
								sigPad.current?.clear()
							}} >
								<TranslatedMessage id="signatures.reset" defaultMessage="effacer" description="Reset" />
							</ResetButton>
						</div>
					)}
					<SignaturePad
						canvasProps={{ className: `sigPad ${onFocus ? 'focus' : ''}` }}
						ref={sigPad}
						clearOnResize={false}
						onBegin={() => setOnFocus(true)}
						onEnd={() => {
							setOnFocus(false)
							if (sigPad && sigPad.current) {
								//@ts-ignore
								input.onChange(sigPad.current.getTrimmedCanvas().toDataURL('image/png'))
							}
						}}
						{...input}
					/>
					{meta.error && meta.touched && (
						<p>
							<TranslatedMessage
								id={meta.error}
								defaultMessage="Error format"
								description="Error message"
							/>
						</p>
					)}
				</div>
			)}
		</Field>
	)
}
export default Signature
