import { Button, ButtonProps, makeStyles, Theme } from '@material-ui/core'
import React from 'react'
import './gaugeButton.css'

type Props = {
	id?: string
	disabled?: boolean
	isfirst?: boolean
	islast?: boolean
	width?: number
	max: number
	selected?: boolean
}

// border: `1px solid ${theme.palette.gauge.separator.main}`,
const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
	gauge: {
		borderTopLeftRadius: props => !!props.isfirst ? 22.5 : 0,
		borderBottomLeftRadius: props => !!props.isfirst ? 22.5 : 0,
		borderTopRightRadius: props => !!props.islast ? 22.5 : 0,
		borderBottomRightRadius: props => !!props.islast ? 22.5 : 0,
		flex: props => !!props.isfirst || !!props.islast ? 0.5 : 1,
		minWidth: 'auto'
	},
	gaugeSelected: {
		border: `solid ${theme.palette.gauge.separator.main}`,
		borderWidth: props => `1px ${!!props.islast ? '1px' : '0.25px'} 1px ${!!props.isfirst ? '1px' : '0.25px'}`,
		borderColor: theme.palette.gauge.separator.main,
		backgroundColor: theme.palette.primary.main
	},
	gaugeUnselected: {
		border: `solid ${theme.palette.gauge.separator.main}`,
		borderWidth: props => `1px ${!!props.islast ? '1px' : '0.25px'} 1px ${!!props.isfirst ? '1px' : '0.25px'}`,
		borderColor: theme.palette.gauge.separator.main,
		backgroundColor: 'white'
	}
}))


const GaugeButton = (props: Props & ButtonProps) => {
	const classes = useStyles(props)
	const { isfirst, islast, ...otherProps } = props

	return <Button
		className={`${classes.gauge} ${props.selected ? classes.gaugeSelected : classes.gaugeUnselected}`}
		color="primary"
		variant="contained"
		disableElevation
		{...otherProps}
	/>
}

export default GaugeButton
