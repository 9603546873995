import { FieldValidator } from 'final-form'
import React from 'react'
import { Field } from 'react-final-form'
import ErrorMessage from '../error-message/ErrorMessage'
import './input.css'
import TranslatedMessage from '../../../modules/translation/TranslatedMessage'

type Props = {
	label?: string
	id?: string
	name: string
	validate?: FieldValidator<any> | undefined
	children?: React.ReactNode
	dynamicLabel?: string
	optional?: boolean
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

const Input = ({ label, id, validate, children, dynamicLabel, optional = false, ...rest }: Props) => {
	return (
		<Field name={rest.name} validate={validate}>
			{({ input, meta }) => (
				<div className={`input ${meta.touched && meta.error ? 'error' : ''}`}>
					{(label || dynamicLabel) && <label htmlFor={id}>{dynamicLabel ?? <TranslatedMessage id={label} defaultMessage="Label" description="Input label" />} {optional ? <TranslatedMessage id="tools.notMandatory" defaultMessage=" - FACULTATIF" description="Optional" /> : ''}</label>}
					<div className="flex">
						<input {...input} {...rest} id={id} />
						{children}
					</div>
					<ErrorMessage meta={meta} />
				</div>
			)}
		</Field>
	)
}

export default Input
