import { DAMAGE_FACE_ENUM, FACE_KEY_NAME } from '../utils/constants'
import { BOOLEAN, DRIVER_INFO, FACE_STATE, MEDIA_TYPE, RIV_STEP_TYPE, STEP_STATE, TOOL_ATTRIBUTE_TYPE, TOOLS_TYPE } from '../utils/enums'
import { base64ToFile } from '../utils/fileUtils'

export const saveSearchVehicle = (data: { selectedVehicle: Vehicle; selectedModel: AssetModel }): SaveSearchVehicle => ({
	idModel: data.selectedModel.id,
	plateNumber: data.selectedVehicle.vehicleRegistration,
	parcNumber: data.selectedVehicle.parcNumber
})

export const saveClientMovement = (data: { values: ClientMovementForm }): SaveClientMovement => ({
	contractOwner: data.values.name,
	movementCode: data.values.type,
	movementComment: data.values.movementComment
})

export const loadClientMovement = (data: SaveInfo, step: StepInfo) => {
	if (data.riv.contractOwner && data.riv.movementCode) {
		step.state = STEP_STATE.DONE
		step.data.values = {
			name: data.riv.contractOwner,
			type: data.riv.movementCode,
			movementComment: data.riv.movementComment
		}
	}
}

export const saveTools = (data: { values: SaveTools }) => {
	if (!data.values) {
		return { tools: [] }
	}
	const medias: MediaInfo[] = []
	if (data.values.pictures) {
		data.values.pictures.forEach((files) => medias.push({ type: MEDIA_TYPE.CABINE, file: files[0] }))
	}

	return { tools: data.values.tools, deleteMediaIds: data.values.deletedPicturesId, medias, equipmentComment: data.values.equipmentComment, cabinComment: data.values.cabinComment }
}

export const loadTools = (data: SaveInfo, step: StepInfo, rivInfo: RivInfo, tools: Tools) => {
	const toolType: string = (step.content as any).props?.toolType
	if (tools[toolType]) {
		const cabinTools = tools[toolType]
		const values: SaveToolState[] = []
		step.state = STEP_STATE.DONE
		cabinTools.forEach((tool) => {
			const value = data.rivAttributeValues.find((rivAttribute) => rivAttribute.idRivAttributeModel === tool.rivAttributeModel.id)?.value
			if (value === undefined) {
				// Non finalisé si :
				// - un champ obligatoire n'est pas rempli
				// - un champ optionnel de type radio n'est pas rempli (l'user doit cocher "Not concerned")
				// - champ numérique exempt de cette règle
				if (tool.rivAttributeModel.type === TOOL_ATTRIBUTE_TYPE.NUMERIC) {
					// Cas particulier pour le champ numérique, par défaut 0
					values.push({ id: tool.rivAttributeModel.id, value: '0' })
				} else if (tool.mandatory || (!tool.mandatory && tool.rivAttributeModel.type === TOOL_ATTRIBUTE_TYPE.YES_NO)) {
					step.state = STEP_STATE.TODO
				}
			} else if (tool.rivAttributeModel.type === TOOL_ATTRIBUTE_TYPE.NUMERIC) {
				// Cas particulier pour le champ numérique, par défaut 0
				values.push({ id: tool.rivAttributeModel.id, value: value || '0' })
			} else if (data.rivStep.draft || !tool.clearOnReturn) {
				values.push({ id: tool.rivAttributeModel.id, value })
			} else {
				step.state = STEP_STATE.TODO
			}
		})
		if (toolType === TOOLS_TYPE.CABIN && rivInfo.draft) {
			step.data.values = {
				tools: values,
				loadedPictures: data.rivMedias.filter((rivMedia) => rivMedia.type === MEDIA_TYPE.CABINE),
				deletedPicturesId: [],
				cabinComment: data.rivStep.cabinComment
			}
		} else if (toolType === TOOLS_TYPE.EQUIPMENT) {
			step.data.values = { tools: values, equipmentComment: data.rivStep.equipmentComment }
		} else {
			step.data.values = { tools: values, cabinComment: data.rivStep.cabinComment }
		}
	}
}

export const saveVehicleState = (data: { values?: OutsideStateForm }): SaveVehicleState => {
	if (!data.values) {
		return {}
	}
	let rivDamages: SaveImpact[] = []
	const medias: MediaInfo[] = []
	const deleteMediaIds: string[] = []
	const faces: any = {}
	data.values.faces.forEach((face, i) => {
		rivDamages = rivDamages.concat(face.impacts.map((impact) => ({ ...impact, faceIndex: i + 1 })))
		if (face.faceState) {
			faces[face.key] = face.faceState
		}
		if (face.comment) {
			faces[`${face.key}Comment`] = face.comment
		}
		face.pictures?.forEach((files) => medias.push({ type: DAMAGE_FACE_ENUM[i], file: files[0] }))
		face.deletedPicturesId.forEach((id) => deleteMediaIds.push(id))
	})
	return { rivDamages, medias, ...faces, deleteMediaIds }
}

export const loadVehicleState = (data: SaveInfo, step: StepInfo, rivInfo: RivInfo, tools: Tools, assetModel?: AssetModel) => {
	const keys = Object.keys(assetModel ?? [])
		.filter((key) => key.includes(FACE_KEY_NAME) && assetModel && assetModel[key] !== null)
		.sort()
	if (rivInfo.type === RIV_STEP_TYPE.BACK && !data.rivStep.draft) {
		step.data.values = {
			faces: keys.map(
				(key, i): OutsideStateFace => ({
					key,
					impacts: data.rivDamages ? data.rivDamages.filter((damage) => damage.faceIndex === i + 1).map((damage) => ({ type: damage.type, x: damage.xcoordinate, y: damage.ycoordinate, fromGoStep: true })) : [],
					faceState: data.rivStep[key] ?? '',
					comment: '',
					loadedPictures: [],
					deletedPicturesId: []
				})
			)
		}
		return
	}
	step.data.values = {
		faces: keys.map(
			(key, i): OutsideStateFace => ({
				key,
				impacts: data.rivDamages ? data.rivDamages.filter((damage) => damage.faceIndex === i + 1).map((damage) => ({ type: damage.type, x: damage.xcoordinate, y: damage.ycoordinate, fromGoStep: damage.fromGoStep })) : [],
				faceState: data.rivStep[key] ?? '',
				comment: data.rivStep[`${key}Comment`] ?? '',
				loadedPictures: data.rivMedias.filter((rivMedia) => rivMedia.type === DAMAGE_FACE_ENUM[i]),
				deletedPicturesId: []
			})
		)
	}
	const done = keys.every((key, i) => {
		if (!data.rivStep[key] || !data.rivMedias.filter((rivMedia) => rivMedia.type === DAMAGE_FACE_ENUM[i]).length) {
			return false
		}
		if (data.rivStep[key] === FACE_STATE.IMPACT && !data.rivDamages.filter((damage) => damage.faceIndex === i + 1).length) {
			return false
		}
		return true
	})
	if (done) {
		step.state = STEP_STATE.DONE
	}
}

export const loadVehicleStateFromPreviousBackStep = (assetModel: AssetModel, previousBackStepDamages: RivDamage[], step?: StepInfo) => {
	if (!step){
		return
	}

	const keys = Object.keys(assetModel)
		.filter((key) => key.includes(FACE_KEY_NAME) && assetModel && assetModel[key] !== null)
		.sort()

	step.data.values = {
		faces: keys.map((key, i): OutsideStateFace => {
			const previousFaceDamages = previousBackStepDamages.filter((damage) => damage.faceIndex === i + 1).map((damage) => ({ type: damage.type, x: damage.xcoordinate, y: damage.ycoordinate, fromGoStep: true, fromPreviousBackStep: true }))
				return ({
					key,
					impacts: previousFaceDamages,
					faceState: previousFaceDamages.length > 0 ? FACE_STATE.IMPACT : '',
					comment: '',
					loadedPictures: [],
					deletedPicturesId: []
				})
			}
		)
	}
}

export const saveDriver = (data: { values?: DriverForm }) => {
	if (!data.values) {
		return {}
	}
	const driverData: any = {}
	if (data.values.driverInfo === DRIVER_INFO.MANUAL && data.values.manual) {
		const manual = data.values.manual
		driverData.driverAge21 = manual.age
		driverData.driverExp1 = manual.year
		driverData.driverLicenceCategory = manual.category
		driverData.driverFirstname = manual.firstname
		driverData.driverLastname = manual.lastname
		driverData.driverLicenceExpiry = manual.validDate ? new Date(manual.validDate).toISOString() : undefined
		driverData.driverLicenceNumber = manual.licenseNumber
	}
	return { driverInfo: data.values.driverInfo, ...driverData }
}

export const loadDriver = (data: SaveInfo, step: StepInfo, rivInfo: RivInfo) => {
	// Dans le cas d'un retour, on ne saisit pas le chauffeur
	if (rivInfo.type === RIV_STEP_TYPE.BACK) {
		step.state = STEP_STATE.DISABLED
		step.data.values = {
			driverInfo: DRIVER_INFO.NONE
		}
		return
	}

	if (!data.rivStep.driverInfo) {
		return
	}
	step.data.values = {
		driverInfo: data.rivStep.driverInfo,
		manual:
			data.rivStep.driverInfo === DRIVER_INFO.MANUAL
				? {
					age: data.rivStep.driverAge21,
					year: data.rivStep.driverExp1,
					category: data.rivStep.driverLicenceCategory,
					firstname: data.rivStep.driverFirstname,
					lastname: data.rivStep.driverLastname,
					licenseNumber: data.rivStep.driverLicenceNumber,
					validDate: data.rivStep.driverLicenceExpiry ? data.rivStep.driverLicenceExpiry.split('T')[0] : undefined
				}
				: undefined
	}
	if (data.rivStep.driverInfo) {
		switch (data.rivStep.driverInfo) {
			case DRIVER_INFO.MANUAL:
				if (step.data.values.manual) {
					const manualValues = Object.keys(step.data.values.manual)
					if (manualValues.length && Object.keys(step.data.values.manual).every((value) => !!value)) {
						step.state = STEP_STATE.DONE
					}
				}
				break
			case DRIVER_INFO.NONE:
				step.state = STEP_STATE.DONE
				break
		}
	}
}

export const loadSignature = (data: SaveInfo, step: StepInfo) => {
	if (data.riv.contractOwner && data.riv.movementCode) {
		step.state = STEP_STATE.TODO
	}
	step.data.values = {
		clientEmails: ['']
	}
}

export const saveSignature = (data: { values?: SignaturesForm }) => {
	if (!data.values) {
		return {}
	}
	const medias: MediaInfo[] = []
	const values = data.values
	const addMedia = (b64: string, mediaType: string, mimeType: string) => {
		const file = base64ToFile(b64, mediaType, mimeType)
		medias.push({ type: mediaType, file })
	}
	if (values.driverSignature) {
		addMedia(values.driverSignature, MEDIA_TYPE.CUSTOMER_SIGNATURE, 'image/png')
	}
	if (values.renterSignature) {
		addMedia(values.renterSignature, MEDIA_TYPE.RENTER_SIGNATURE, 'image/png')
	}

	return {
		customerEmail: values.clientEmails && values.clientEmails.length > 0 ? values.clientEmails.join(';') : undefined,
		driverCompliance: values.driverAuthorization,
		customerPresence: values.driverIsPresent ? values.driverIsPresent === BOOLEAN.TRUE : undefined,
		customerLastname: values.driverLastname,
		customerFirstname: values.driverFirstname,
		customerConcern: values.driverConcern,
		renterLastname: values.renterLastname,
		renterFirstname: values.renterFirstname,
		medias
	}
}

export const resetRivSteps = (stepsInfo: StepInfo[], activeStep?: number) => {
	stepsInfo.forEach((step, i) => {
		if (!!activeStep && i === activeStep) {
			return
		}
		step.state = STEP_STATE.TODO
		step.data = { ...step.initialData }
	})
}
