import { Typography } from '@material-ui/core'
import React, { ReactNode } from 'react'
import './header.css'
import TranslatedMessage from '../../modules/translation/TranslatedMessage'

type Props = {
	isUploading?: boolean
	children?: ReactNode
	openSyncDialog?: () => void
}

const Header = ({ children, isUploading, openSyncDialog }: Props) => {
	return (<>
			<header>
				{isUploading && <div className="upload-banner" onClick={openSyncDialog}>
					<Typography variant="body1" component="p" style={{ textAlign: 'center', color: 'white' }}>
						<TranslatedMessage id="home.upload" defaultMessage="RIV en cours d'envoi. L'application doit rester ouverte." description="Upload warning" />
					</Typography>
				</div>}
				<Typography variant="h1" component="h1" style={{ paddingBottom: isUploading ? 5 : 13}}>
					{children}
				</Typography>
			</header>
		</>
	)
}

export default Header
