import React, { useContext } from 'react'
import { TranslationContext } from './TranslationContext'
import { FormattedMessage, useIntl } from 'react-intl'

type TranslatedMessageProps = {
	id?: string
	defaultMessage: string
	description: string
	values?: {
		[key: string]: any
	}
}

const DEFAULT_LANGUAGES = ['fr', 'en']

const TranslatedMessage = ({ id, defaultMessage, description, values }: TranslatedMessageProps) => {
	const { translations } = useContext(TranslationContext)
	const intl = useIntl()

	if (!id) {
		return <></>
	} else if (translations && translations[id]) {
		let translatedText = translations[id]
		if (!!values && Object.keys(values).length > 0) {
			Object.keys(values).forEach((key: string) => translatedText = translatedText.replace(`{${key}}`, values[key]))
		}
		return <span>{translatedText}</span>
	} else if (DEFAULT_LANGUAGES.includes(intl.locale)) {
		return <FormattedMessage id={id} defaultMessage={defaultMessage} description={description} values={values || {}} />
	}

	return <span>{id}</span>
}

export default TranslatedMessage
