import React, { createContext, ReactNode, useEffect, useState } from 'react'
import { getLanguages, getTranslations } from '../../services/api'
import { useIntl } from 'react-intl'

type TranslationState = {
	translations: { [key: string]: string }
	languages: Language[]
}

export const TranslationContext = createContext<TranslationState>({
	translations: {},
	languages: []
})

type Props = {
	children: ReactNode
}

export const TranslationProvider = ({ children }: Props) => {
	const intl = useIntl()
	const [translations, setTranslations] = useState<{ [key: string]: string }>({})
	const [languages, setLanguages] = useState<Language[]>([])
	const [loading, setLoading] = useState<boolean>(true)

	useEffect(() => {
		getLanguages()
			.then((languagesList) => setLanguages(languagesList))
	}, [])

	useEffect(() => {
		getTranslations(intl.locale)
			.then((translationsList) => setTranslations(
				translationsList.reduce((acc, curr) => ({
					...acc,
					[curr.i18nKey]: curr.i18nValue
				}), {})))
			.finally(() => setLoading(false))
	}, [intl])

	if (loading) {
		return <></>
	}

	return (
		<TranslationContext.Provider value={{ translations, languages }}>
			{children}
		</TranslationContext.Provider>
	)
}
