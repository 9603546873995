import React, { useCallback } from 'react'
import './gauge.css'
import { FieldValidator } from 'final-form'
import { Field } from 'react-final-form'
import ErrorMessage from '../error-message/ErrorMessage'
import GaugeButton from './components/GaugeButton'
import { ResetButton } from '../../buttons/Buttons'
import TranslatedMessage from '../../../modules/translation/TranslatedMessage'

type Props = {
	label?: string
	id?: string
	name: string
	type: GaugeType
	disabled?: boolean
	validate?: FieldValidator<any> | undefined
	dynamicLabel?: string,
	optional?: boolean
}

const Gauge = ({ label, id, name, validate, type, disabled, dynamicLabel, optional = false, ...input }: Props) => {

	const gaugeButtons = useCallback((input) => {
		let buttons: JSX.Element[] = []
		for (let i = 0; i < type.length; i++) {
			buttons.push(<GaugeButton
				key={i}
				id={type[i]}
				isfirst={i === 0 ? true : undefined}
				islast={i === type.length - 1 ? true : undefined}
				disabled={disabled}
				max={type.length}
				selected={input.value ? i <= type.indexOf(input.value) : false}
				onClick={() => input.onChange(type[i])} />)
		}
		return buttons
	}, [type, disabled])

	return (
		<Field name={name} validate={validate}>
			{({ input, meta }) => (
				<div className={`gauge ${meta.error ? 'error' : ''}`}>
					{(label || dynamicLabel) && (
						<div className="flex">
							<label htmlFor={id}>{dynamicLabel ?? <TranslatedMessage id={label} defaultMessage="Label" description="Input label" />} {optional ? <TranslatedMessage id="tools.notMandatory" defaultMessage=" - FACULTATIF" description="Optional" /> : ''}</label>
							{optional && <ResetButton size="small" onClick={() => input.onChange(undefined)}>
								<TranslatedMessage id="signatures.reset" defaultMessage="effacer" description="Reset" />
							</ResetButton>}
						</div>
					)}
					<div className="slider">
						{gaugeButtons(input)}
					</div>
					<p>
						<TranslatedMessage
							id="form.gauges.selected_value"
							defaultMessage={`Sélectionné : ${input.value}`}
							description="Selected value"
							values={{ value: input.value }}
						/>
					</p>
					<ErrorMessage meta={meta} />
				</div>
			)}
		</Field>
	)
}
export default Gauge
