import imageCompression from 'browser-image-compression'
import { ZONE_CODE } from '../utils/enums'
import { LOCAL_STORAGE_ITEM, MEDIA_TIME_OUT } from '../utils/constants'

export const getSearchVehicles = async (params: SearchForm): Promise<Vehicle[]> => {
	const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/vehicle?${new URLSearchParams({ ...params, zone: (localStorage.getItem(LOCAL_STORAGE_ITEM.ZONE) || ZONE_CODE.EU) })}`)
	return await response.json()
}

export const postData = async (data: SaveData): Promise<SaveInfo> => {
	const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/riv`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(data)
	})
	return response.status === 200 ? response.json() : undefined
}

export const putData = async (data: SaveData): Promise<SaveInfo> => {
	const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/riv`, {
		method: 'PUT',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(data)
	})
	return response.status === 200 ? response.json() : undefined
}

export const deleteData = async (idRiv: string) => {
	const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/riv/${idRiv}`, {
		method: 'DELETE'
	})
	return response.status
}

export const putMedia = async (idRivStep: string, type: string, imageFile: File, destinationId?: string): Promise<MediaResponse | undefined> => {
	const form = new FormData()
	const compressOptions = {
		maxSizeMB: 0.25,
		maxWidthOrHeight: 1920,
		useWebWorker: true
	}
	const compressedFile = await imageCompression(imageFile, compressOptions)
	form.append('mediaType', type)
	form.append('file', compressedFile)
	if (destinationId) {
		form.append('destinationId', destinationId)
	}

	try {
		const controller = new AbortController()

		const timeoutId = setTimeout(() => {
				controller.abort()
				return undefined
			},
			MEDIA_TIME_OUT * 1000
		)

		//@ts-ignore
		return fetch(`${process.env.REACT_APP_SERVER_URL}/api/media/${idRivStep}`, {
			method: 'PUT',
			body: form,
			signal: controller.signal
		}).then(response => {
			clearTimeout(timeoutId)
			return response.status === 200 ? response.json() : undefined
		}).catch(e => {
			return undefined
		})

	} catch (e) {
		return undefined
	}
}

export const getCgu = async (lang: string): Promise<CguInfo> => {
	const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/cgu?${new URLSearchParams({ lang })}`)
	return response.json()
}

export const getTranslations = async (lang: string): Promise<Translation[]> => {
	const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/translation?${new URLSearchParams({ lang })}`)
	return response.json()
}

export const getLanguages = async (): Promise<Language[]> => {
	const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/pf-language`)
	return response.json()
}

export const getRivStep = async (idRivStep: string): Promise<RivStep> => {
	let response: undefined | Response = undefined
	try {
		response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/riv?${new URLSearchParams({ idRivStep })}`)
	} catch (e) {
		console.error(e)
	}
	return response && response.status === 200 ? response.json() : undefined
}

export const getMovements = async (): Promise<Movement[]> => {
	const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/movement`)
	return response.json()
}

export const health = async (): Promise<Response> => {
	return await fetch(`${process.env.REACT_APP_SERVER_URL}/api/health`)
}
