import React, { useEffect, useRef, useState } from 'react'
import ImpactDialog from '../../modules/dialog/impact/ImpactDialog'
import SimpleWarningDialog from '../../modules/dialog/warning/SimpleWarningDialog'
import { alphabet } from '../../utils/constants'

type Props = {
	imagePath: string
	push: (value: ImpactInfo) => void
	disabled?: boolean
	impacts: ImpactInfo[]
	fromGoStep: boolean
}

const CanvasPoints = ({ imagePath, push, disabled, impacts, fromGoStep }: Props) => {
	const canvasRef = useRef<HTMLCanvasElement>(null)
	const [img, setImg] = useState<HTMLImageElement>()
	const [openDialog, setOpenDialog] = useState(false)
	const [mouseEvent, setMouseEvent] = useState<React.MouseEvent<HTMLCanvasElement, MouseEvent>>()
	const [openWarningDialog, setOpenWarningDialog] = useState(false)

	useEffect(() => {
		const loadImg = () => {
			const image = new Image()
			image.src = imagePath
			image.onload = () => {
				setImg(image)
			}
		}
		loadImg()
	}, [imagePath])

	useEffect(() => {
		if (canvasRef.current && img) {
			const canvas = canvasRef.current
			const context = canvas.getContext('2d')
			const rect = canvas.getBoundingClientRect()
			const ratio = img.width / rect.width
			if (!context) {
				return
			}
			context.drawImage(img, 0, 0)
			impacts.forEach((impact, i) => {
				drawPoints(impact.x, impact.y, impact.fromGoStep || fromGoStep, ratio, context, alphabet[i])
			})
		}
	}, [img, impacts, fromGoStep])

	const handleClick = (e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
		if (disabled) {
			return
		}
		if (impacts.length >= 10) {
			setOpenWarningDialog(true)
			return
		}
		setOpenDialog(true)
		setMouseEvent(e)
	}

	const drawPoints = (x: number, y: number, isStart: boolean, ratio: number, context: CanvasRenderingContext2D, fillText: string) => {
		// Aller = rond vert
		// Retour = triangle vert
		if (isStart) {
			context.beginPath()
			context.arc(x, y, 20 * ratio, 0, 2 * Math.PI)
			context.strokeStyle = 'white'
			context.fillStyle = 'rgba(40, 155, 83)'
			context.fill()
			context.stroke()
			context.font = `${15 * ratio}px Inter`
			context.fillStyle = 'white'
			context.textAlign = 'center'
			context.textBaseline = 'middle'
			context.fillText(fillText, x, y)
			context.closePath()
		 } else {
			context.beginPath();
			const x1 = x
			const y1 = y - (25 * ratio)
			const x2 = x1 + (20 * ratio)
			const y2 = y1 + (40 * ratio)

			context.moveTo(x1, y1);
			context.lineTo(x2, y2);
			context.lineTo(x2 -(40 * ratio), y2);
			context.fillStyle = 'rgba(40, 155, 83)'
			context.fill();

			context.font = `${15 * ratio}px Inter`
			context.fillStyle = 'white'
			context.textAlign = 'center'
			context.textBaseline = 'middle'
			context.fillText(fillText, x, y + (2 * ratio))

			context.closePath();
		}


	}

	const handleChoose = (value: string) => {
		if (canvasRef.current && img && mouseEvent) {
			const canvas: HTMLCanvasElement = canvasRef.current
			const context = canvas.getContext('2d')
			const rect = canvas.getBoundingClientRect()
			const ratio = img.width / rect.width
			const mouseCanvasX = (mouseEvent.clientX - rect.x) * ratio
			const mouseCanvasY = (mouseEvent.clientY - rect.y) * ratio
			if (!context) {
				return
			}
			drawPoints(mouseCanvasX, mouseCanvasY, fromGoStep, ratio, context, alphabet[impacts.length + 1])
			push({ type: value, x: mouseCanvasX, fromGoStep, y: mouseCanvasY })
		}
	}

	return (
		<>
			<canvas ref={canvasRef} width={img?.width} height={img?.height} onMouseDown={handleClick}></canvas>
			<ImpactDialog open={openDialog} setOpen={setOpenDialog} onChoose={handleChoose} />
			<SimpleWarningDialog open={openWarningDialog} setOpen={setOpenWarningDialog} contentLabel="outside_state.dialog.warning.content" />
		</>
	)
}

export default CanvasPoints
