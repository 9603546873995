import { IconButton } from '@material-ui/core'
import { Add, Remove } from '@material-ui/icons'
import React from 'react'
import { Field } from 'react-final-form'
import ErrorMessage from '../error-message/ErrorMessage'
import './number-input.css'
import TranslatedMessage from '../../../modules/translation/TranslatedMessage'

type Props = {
	name: string
	label?: string
	id?: string
	dynamicLabel?: string
}

const NumberInput = ({ name, label, id, dynamicLabel }: Props) => {
	return (
		<Field name={name} defaultValue="0">
			{({ input, meta }) => (
				<div className={`number-input ${meta.touched && meta.error ? 'error' : ''}`}>
					{(label || dynamicLabel) && (
						<label htmlFor={id} className="mb-1">
							{dynamicLabel ?? <TranslatedMessage id={label} defaultMessage="Label" description="Input label" />}
						</label>
					)}
					<input {...input} type="number" id={id} />
					<div className="component">
						<IconButton
							size="small"
							onClick={() => input.onChange(Number(input.value) - 1)}
							disabled={Number(input.value) === 0}
						>
							<Remove />
						</IconButton>
						<div className="value">{input.value}</div>
						<IconButton size="small" onClick={() => input.onChange(Number(input.value) + 1)}>
							<Add />
						</IconButton>
					</div>
					<ErrorMessage meta={meta} />
				</div>
			)
			}
		</Field>
	)
}

export default NumberInput
