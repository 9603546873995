import React, { useContext } from 'react'
import Bottom from '../../bottom/Bottom'
import { Form } from 'react-final-form'
import { StepperContext } from '../../StepperContext'
import RadioGroup, { RadioOption } from '../../../../components/field/radio/RadioGroup'
import Input from '../../../../components/field/input/Input'
import Signature from '../../../../components/field/signature/Signature'
import { regexEmail } from '../../../../utils/regexUtils'
import './signature.css'
import RivCheckbox from '../../../../components/field/checkbox/Checkbox'
import PristineListener from '../../PristineListener'
import { BOOLEAN, RIV_STEP_TYPE } from '../../../../utils/enums'
import TranslatedMessage from '../../../translation/TranslatedMessage'
import { FieldArray } from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays'
import { Add, Remove } from '@material-ui/icons'
import { IconButton } from '@material-ui/core'

const validateEmail = (value: string) => {
	if (!value) {
		return 'errors.form.required'
	} else if (!value.match(regexEmail)) {
		return 'errors.form.emailFormat'
	}
	return undefined
}

const Signatures = () => {
	const { currentStepInfo, rivInfo } = useContext(StepperContext)

	const sendValues = (values: SignaturesForm) => {
		currentStepInfo.data = { ...currentStepInfo.data, values }
	}

	const validate = (values: SignaturesForm) => {

		const errors: any = {}

		if (!values.sendClientEmail) {
			errors.sendClientEmail = 'errors.form.required'
		}
		if (!values.driverIsPresent) {
			errors.driverIsPresent = 'errors.form.required'
		}
		if (values.driverIsPresent === trueFalseOptions[0].value) {
			if (!values.driverFirstname) {
				errors.driverFirstname = 'errors.form.required'
			}
			if (!values.driverLastname) {
				errors.driverLastname = 'errors.form.required'
			}
			if (rivInfo.type === RIV_STEP_TYPE.GO) {
				if (!values.driverAuthorization) {
					errors.driverAuthorization = 'errors.form.required'
				}
				if (!values.driverConcern) {
					errors.driverConcern = 'errors.form.required'
				}
			}

			if (!values.driverSignature) {
				errors.driverSignature = 'errors.form.required'
			}
		}
		if (!values.renterFirstname) {
			errors.renterFirstname = 'errors.form.required'
		}
		if (!values.renterLastname) {
			errors.renterLastname = 'errors.form.required'
		}
		if (!values.renterSignature) {
			errors.renterSiganture = 'errors.form.required'
		}
		return errors
	}

	const trueFalseOptions: RadioOption[] = [
		{
			label: <TranslatedMessage id="signatures.radios.values.yes" defaultMessage="Oui" description="Yes" />,
			value: BOOLEAN.TRUE
		},
		{
			label: <TranslatedMessage id="signatures.radios.values.no" defaultMessage="Non" description="No" />,
			value: BOOLEAN.FALSE
		}
	]

	return (
		<Form
			onSubmit={sendValues}
			validate={validate}
			mutators={{
				...arrayMutators
			}}
			initialValues={{ ...currentStepInfo.data.values }}
			render={({ handleSubmit, invalid, values, pristine, errors }) => (<>
					<PristineListener pristine={pristine} />
					<form onSubmit={handleSubmit} className="layout" autoComplete="off">
						<div className="left">
							<RadioGroup
								name="sendClientEmail"
								direction="row"
								options={trueFalseOptions}
								label="signatures.radios.labels.send_mail_to_client"
							/>
							{values.sendClientEmail === trueFalseOptions[0].value &&
							<FieldArray name="clientEmails">
								{({ fields }) => (<>
									{fields.map((name, i) => <div className="client-email" key={`${name}-input-${i}`}>
											<Input
												name={`${name}`}
												label={i === 0 ? 'signatures.inputs.client_email' : undefined}
												type="text"
												validate={validateEmail}
											>
												{i === (fields.length || 0) - 1 &&
												<IconButton
													size="small"
													onClick={() => fields.push('')}
												>
													<Add />
												</IconButton>
												}
												<IconButton
													size="small"
													disabled={i === 0}
													onClick={() => fields.remove(i)}
												>
													<Remove />
												</IconButton>
											</Input>
										</div>
									)}
								</>)}
							</FieldArray>}
							<div className="divider" />
							<RadioGroup
								name="driverIsPresent"
								direction="row"
								options={trueFalseOptions}
								label="signatures.radios.labels.driver_is_present"
							/>
							{values.driverIsPresent === trueFalseOptions[0].value && (
								<>
									<Input
										name="driverFirstname"
										label="signatures.inputs.driver_firstname"
										type="text"
									/>
									<Input
										name="driverLastname"
										label="signatures.inputs.driver_lastname"
										type="text"
									/>
									{rivInfo.type === RIV_STEP_TYPE.GO && <>
										<RivCheckbox name="driverAuthorization">
											<TranslatedMessage
												id="signatures.checkbox.driver_authorization"
												defaultMessage="Le chauffeur accepte que les informations relatives à son permis soient transmises."
												description="check box description"
											/>
										</RivCheckbox>
										<RivCheckbox name="driverConcern">
											<TranslatedMessage
												id="signatures.checkbox.driver_concern"
												defaultMessage="Le client est informé du fonctionnement du véhicule et de ses particularités (gabarit, hauteur)"
												description="driver concern"
											/>
										</RivCheckbox>
									</>}

									<Signature
										name="driverSignature"
										label="signatures.signature.driver"
									/>
								</>
							)}
						</div>
						<div className="right">
							<Input
								name="renterFirstname"
								label={'signatures.inputs.renter_firstname'}
								type="text"
							/>
							<Input
								name="renterLastname"
								label={'signatures.inputs.renter_lastname'}
								type="text"
							/>
							<Signature
								name="renterSignature"
								label="signatures.signature.renter"
							/>
						</div>
					</form>
					<Bottom disabled={invalid} onNext={() => sendValues(values)} />
				</>
			)}
		/>
	)
}

export default Signatures
