import React, { createContext, ReactNode, useEffect, useState } from 'react'
import { getMovements } from '../services/api'

type MovementState = {
	movements: Movement[]
}

export const MovementContext = createContext<MovementState>({
	movements: []
})

type Props = {
	children: ReactNode
}

export const MovementProvider = ({ children }: Props) => {
	const [movements, setMovements] = useState<Movement[]>([])

	useEffect(() => {
		getMovements()
			.then(setMovements)
	}, [])

	return (
		<MovementContext.Provider value={{ movements }}>
			{children}
		</MovementContext.Provider>
	)
}
