import React, { useContext } from 'react'
import { Form } from 'react-final-form'
import Input from '../../../../components/field/input/Input'
import RadioGroup, { RadioOption } from '../../../../components/field/radio/RadioGroup'
import { DRIVER_INFO } from '../../../../utils/enums'
import Bottom from '../../bottom/Bottom'
import PristineListener from '../../PristineListener'
import { StepperContext } from '../../StepperContext'
import TranslatedMessage from '../../../translation/TranslatedMessage'
import RivCheckbox from '../../../../components/field/checkbox/Checkbox'

const Driver = () => {
	const { currentStepInfo } = useContext(StepperContext)

	const handleSave = (values: DriverForm) => {
		currentStepInfo.data = { values }
	}

	const driverInfoOptions: RadioOption[] = [
		{
			label: <TranslatedMessage id="driver.radios.driver_info.manual" defaultMessage="Remplir manuellement" description="Manual input" />,
			value: DRIVER_INFO.MANUAL
		},
		{
			label: <TranslatedMessage id="driver.radios.driver_info.none" defaultMessage="Pas de chauffeur" description="No driver" />,
			value: DRIVER_INFO.NONE
		}
	]

	const validate = (values: DriverForm) => {
		const errors: any = { faces: [] }
		if (!values.driverInfo) {
			errors.driverInfo = 'errors.form.required'
		}
		if (values.driverInfo === DRIVER_INFO.MANUAL) {
			if (!values.manual) {
				errors.manual = 'errors.form.required'
			} else {
				errors.manual = {}
				if (!values.manual.firstname) {
					errors.manual.firstname = 'errors.form.required'
				}
				if (!values.manual.lastname) {
					errors.manual.lastname = 'errors.form.required'
				}
				if (!values.manual.licenseNumber) {
					errors.manual.licenseNumber = 'errors.form.required'
				}
				if (!values.manual.validDate) {
					errors.manual.validDate = 'errors.form.required'
				} else if (new Date(values.manual.validDate).getTime() - nextDay().getTime() < 1) {
					errors.manual.validDate = 'errors.form.futureDate'
				}
				if (!values.manual.age) {
					errors.manual.age = 'errors.form.required'
				}
				if (!values.manual.year) {
					errors.manual.year = 'errors.form.required'
				}
				if (!values.manual.category) {
					errors.manual.category = 'errors.form.required'
				}
			}
		}
		return errors
	}

	const nextDay = () => new Date(Date.now() + 3600 * 1000 * 24)

	return (
		<Form
			onSubmit={handleSave}
			validate={validate}
			initialValues={currentStepInfo.data.values}
			render={({ handleSubmit, invalid, values, form, pristine }) => (
				<>
					<PristineListener pristine={pristine} />
					<form className="layout" onSubmit={handleSubmit} autoComplete="off">
						<div className="right">
							<RadioGroup direction="column" name="driverInfo" options={driverInfoOptions} label="driver.radios.driver_info.label" />
						</div>
						<div className="left">
							{values.driverInfo === DRIVER_INFO.MANUAL && (
								<>
									<Input name="manual.firstname" label="driver.inputs.firstname" />
									<Input name="manual.lastname" label="driver.inputs.lastname" />
									<Input name="manual.licenseNumber" label="driver.inputs.license_number" />
									<Input name="manual.validDate" label="driver.inputs.valid_date" type="date" min={nextDay().toISOString().split('T')[0]} />
									<RivCheckbox name="manual.age">
										<TranslatedMessage
											id="driver.inputs.age"
											defaultMessage="Le conducteur est âgé de minimum 21 ans"
											description="driver age requirement"
										/>
									</RivCheckbox>
									<RivCheckbox name="manual.year">
										<TranslatedMessage
											id="driver.inputs.year"
											defaultMessage="Le permis de conduire national est en cours de validité depuis plus d'un an"
											description="driver licence requirement"
										/>
									</RivCheckbox>
									<RivCheckbox name="manual.category">
										<TranslatedMessage
											id="driver.inputs.category"
											defaultMessage="Le permis est correspondant au tonnage et à la catégorie du véhicule loué"
											description="driver licence category"
										/>
									</RivCheckbox>
								</>
							)}
						</div>
					</form>
					<Bottom disabled={invalid} onSave={() => handleSave(values)} onNext={() => form.submit()} />
				</>
			)
			}
		/>
	)
}

export default Driver
