import { createStyles, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, IconButton, Typography, withStyles, WithStyles } from '@material-ui/core'
import React from 'react'
import { Close } from '@material-ui/icons'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import TranslatedMessage from '../translation/TranslatedMessage'

const styles = (theme: Theme) =>
	createStyles({
		root: {
			margin: 'auto',
			marginTop: 40,
			padding: 0,
			letterSpacing: 0.45
		},
		closeButton: {
			position: 'absolute',
			right: 8,
			top: 12,
			color: theme.palette.dialog.close.color.main,
			height: 'min-content'
		}
	})

export interface DialogTitleProps extends WithStyles<typeof styles> {
	id: string
	children: React.ReactNode
	onClose?: () => void
}

export const RivDialogTitle = withStyles(theme => styles(theme))((props: DialogTitleProps) => {
	const { children, classes, onClose, ...other } = props
	return (
		<DialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h2">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<Close />
				</IconButton>
			) : null}
		</DialogTitle>
	)
})

export const RivDialogActions = withStyles(() => ({
	root: {
		padding: 0
	}
}))(DialogActions)

export const RivDialogContent = withStyles(() => ({
	root: {
		padding: 0
	}
}))(DialogContent)

export const RivDialog = withStyles(() => ({
	root: {
		margin: 0
	},
	paper: {
		maxWidth: 295,
		width: 295,
		margin: 20,
		rowGap: 20,
		padding: 20,
		borderRadius: 6
	}
}))((props: DialogProps & { onClose?: () => void; label: string }) => (
	<Dialog {...props}>
		<RivDialogTitle id="model-dialog-title" onClose={props.onClose}>
			<TranslatedMessage id={props.label} defaultMessage="Titre dialogue" description="Dialog Title" />
		</RivDialogTitle>
		<RivDialogContent className="content flex-col"> {props.children} </RivDialogContent>
	</Dialog>
))
