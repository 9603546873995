import React from 'react'
import { Route, Switch } from 'react-router'
import Homepage from '../modules/home/Homepage'
import CguPage from '../modules/cgu/CguPage'
import PreferencesPage from '../modules/preferences/PreferencesPage'

const App = () => {
	return (
		<Switch>
			<Route exact path="/" component={Homepage} />
			<Route exact path="/cgu" component={CguPage} />
			<Route exact path="/preferences" component={PreferencesPage} />
			<Route path="/" component={Homepage} />
			<Route path="*" component={Homepage} />
		</Switch>
	)
}

export default App
