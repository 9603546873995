import { DialogProps } from '@material-ui/core'
import React from 'react'
import { Form } from 'react-final-form'
import { LargeButton } from '../../../components/buttons/Buttons'
import RadioGroup, { RadioOption } from '../../../components/field/radio/RadioGroup'
import { RivDialog, RivDialogActions } from '../DialogStyles'
import TranslatedMessage from '../../translation/TranslatedMessage'

type Props = {
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
	onChoose: (value: string) => void
} & DialogProps

type ImpactForm = {
	impact: string
}

const ImpactDialog = ({ open, setOpen, onChoose, ...rest }: Props) => {
	const handleClose = () => {
		setOpen(false)
	}

	const impactOptions: RadioOption[] = [
		{
			label: <TranslatedMessage id="outside_state.dialog.create.radio.scratch" defaultMessage="Éraflure" description="Scratch damage" />,
			value: 'SCRATCH'
		},
		{
			label: <TranslatedMessage id="outside_state.dialog.create.radio.blow" defaultMessage="Coup" description="Blow damage" />,
			value: 'BLOW'
		},
		{
			label: <TranslatedMessage id="outside_state.dialog.create.radio.broken" defaultMessage="Casse" description="Broken damage" />,
			value: 'BROKEN'
		}
	]

	const validate = (values: ImpactForm) => {
		const errors: any = {}
		if (!values.impact) {
			errors.model = 'errors.form.required'
		}
		return errors
	}

	const handleSubmit = (values: ImpactForm) => {
		onChoose(values.impact)
		setOpen(false)
	}

	return (
		<RivDialog {...rest} open={open} onClose={handleClose} label="outside_state.dialog.create.title">
			<Form
				onSubmit={handleSubmit}
				validate={validate}
				render={({ handleSubmit, invalid }) => (
					<form onSubmit={handleSubmit} className="flex-col" autoComplete="off">
						<RadioGroup direction="column" options={impactOptions} name="impact" label="outside_state.dialog.create.radio.label" />
						<RivDialogActions>
							<LargeButton type="submit" className="full-width" disabled={invalid}>
								<TranslatedMessage id="search_vehicle.dialog.model_selection.next" defaultMessage="Continuer" description="Next button" />
							</LargeButton>
						</RivDialogActions>
					</form>
				)}
			/>
		</RivDialog>
	)
}

export default ImpactDialog
