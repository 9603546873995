import { DialogProps, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { LargeButton } from '../../../components/buttons/Buttons'
import { RivDialog, RivDialogActions } from '../DialogStyles'
import TranslatedMessage from '../../translation/TranslatedMessage'
import { isOnline } from '../../../utils/platformUtils'

type Props = {
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
	contentLabel: string
	onConfirm: () => void
	onLeave: () => void
} & DialogProps

const NetworkActionWarningDialog = ({ open, setOpen, contentLabel, onConfirm, onLeave }: Props) => {
	const [isLoading, setLoading] = useState(false)
	const [showRetry, setShowRetry] = useState(navigator.onLine)

	useEffect(() => {
		const changeStatus = async () => {
			setShowRetry(await isOnline())
		}

		window.addEventListener('online', changeStatus)
		window.addEventListener('offline', changeStatus)

		return () => {
			window.removeEventListener('online', changeStatus)
			window.removeEventListener('offline', changeStatus)
		}
	}, [])

	useEffect(() => {
		if (!open && isLoading) {
			setLoading(false)
		}

	}, [open, isLoading])

	const handleClose = () => {
		setOpen(false)
		if (onLeave) {
			onLeave()
		}
	}

	const handleConfirm = () => {
		setLoading(true)
		onConfirm()
		setOpen(false)
	}

	return (
		<RivDialog open={open} onClose={handleClose} label="dialog.network.warning.title">
			<Typography variant="body1" component="p">
				<TranslatedMessage id={contentLabel} defaultMessage="Action à risque" description="Warning text" />
			</Typography>
			<RivDialogActions>
				<LargeButton className="full-width" onClick={handleClose} variant="outlined">
					<TranslatedMessage id="dialog.network.warning.modify.cancel" defaultMessage="Annuler" description="Cancel button" />
				</LargeButton>
				<LargeButton disabled={!showRetry} className="full-width" onClick={() => !isLoading && open && handleConfirm()}>
					<TranslatedMessage id="dialog.network.warning.modify.retry" defaultMessage="Réessayer" description="Confirm button" />
				</LargeButton>
			</RivDialogActions>
		</RivDialog>
	)
}

export default NetworkActionWarningDialog
