import { Grid, Typography } from '@material-ui/core'
import { InfoOutlined } from '@material-ui/icons'
import React, { useContext } from 'react'
import { Form } from 'react-final-form'
import { useHistory } from 'react-router'
import { LargeButton } from '../../components/buttons/Buttons'
import RivCheckbox from '../../components/field/checkbox/Checkbox'
import Header from '../../components/header/Header'
import { LOCAL_STORAGE_ITEM } from '../../utils/constants'
import { StepperContext } from '../stepper/StepperContext'
import TranslatedMessage from '../translation/TranslatedMessage'

type CguForm = {
	cgu: Boolean
}

const CguPage = () => {
	const { cguInfo } = useContext(StepperContext)
	const history = useHistory()

	const handleCgu = () => {
		if (!cguInfo) {
			return
		}
		localStorage.setItem(LOCAL_STORAGE_ITEM.CGU_VERSION, cguInfo.version.toString())
		history.push('/')
	}

	const validate = (values: CguForm) => {
		const errors: any = {}
		if (!values.cgu) {
			errors.cgu = 'errors.form.required'
		}
		return errors
	}

	return (
		<Grid item>
			<Header>
				<InfoOutlined fontSize="large" />
				<span>
                    <TranslatedMessage id="cgu.title" defaultMessage="Conditions générales" description="Page Title" />
                </span>
			</Header>
			<Form
				onSubmit={handleCgu}
				validate={validate}
				render={({ handleSubmit, invalid }) => (
					<form onSubmit={handleSubmit} className="flex-col pl-4 pr-4 pt-4 pb-4" autoComplete="off">
						{cguInfo && (
							<Typography variant="body1" component="p">
								{cguInfo.text}
							</Typography>
						)}
						<RivCheckbox name="cgu">
							<TranslatedMessage id="cgu.checkbox" defaultMessage="J'accepte les conditions générales d'utilisation" description="Accept ToS" />
						</RivCheckbox>
						<LargeButton type="submit" disabled={invalid}>
							<TranslatedMessage id="cgu.validate" defaultMessage="Valider" description="Validate" />
						</LargeButton>
					</form>
				)}
			/>
		</Grid>
	)
}

export default CguPage
