import { Chip, ChipProps, withStyles } from '@material-ui/core'
import React from 'react'

export const DoneChip = withStyles((theme) => ({
  root: {
    fontSize: 12,
    height: 22,
    color: theme.palette.chip.done.color.main,
    backgroundColor: theme.palette.chip.done.backgroundColor.main,
    fontWeight: 'normal',
  },
}))((props: ChipProps) => <Chip {...props} />)

export const InProgressChip = withStyles((theme) => ({
  root: {
    fontSize: 12,
    height: 22,
    color: theme.palette.chip.inProgress.color.main,
    backgroundColor: theme.palette.chip.inProgress.backgroundColor.main,
    fontWeight: 'normal',
  },
}))((props: ChipProps) => <Chip {...props} />)

export const DisabledChip = withStyles((theme) => ({
	root: {
		fontSize: 12,
		height: 22,
		color: theme.palette.chip.disabled.color.main,
		backgroundColor: theme.palette.chip.disabled.backgroundColor.main,
		fontWeight: 'normal',
	},
}))((props: ChipProps) => <Chip {...props} />)
