export enum STEP_STATE {
    TODO,
    IN_PROGRESS,
    DONE,
    DISABLED
}

export enum CALL_STATE {
    LOADING,
    SUCCESS,
    ERROR,
}

export enum RIV_STEP_TYPE {
    GO = 'ALLER',
    BACK = 'RETOUR',
}

export enum ZONE_CODE {
    EU = 'EU',
    US = 'US',
    UK = 'UK'
}

export enum LANGUAGE_CODE {
    FR = 'fr',
    EN = 'en'
}

export enum FACE_STATE {
    IMPACT = 'IMPACT',
    NOTHING = 'NOTHING',
}

export enum DRIVER_INFO {
    MANUAL = 'MANUAL',
    NONE = 'NONE',
}

export enum BOOLEAN {
    TRUE = 'TRUE',
    FALSE = 'FALSE',
    NOT_CONCERNED = 'NOT_CONCERNED'
}

export enum MEDIA_TYPE {
    FACE1 = 'DAMAGE_FACE_1',
    FACE2 = 'DAMAGE_FACE_2',
    FACE3 = 'DAMAGE_FACE_3',
    FACE4 = 'DAMAGE_FACE_4',
    FACE5 = 'DAMAGE_FACE_5',
    CUSTOMER_SIGNATURE = 'CUSTOMER_SIGNATURE',
    RENTER_SIGNATURE = 'RENTER_SIGNATURE',
    CABINE = 'CABINE'
}

export enum TOOLS_TYPE {
	CABIN = 'CABINE',
	EQUIPMENT = 'EQUIPMENTS'
}

export enum TOOL_ATTRIBUTE_TYPE {
	TEXT = 'TEXT',
    TEXT_NUM = 'TEXT_NUM',
	NUMERIC = 'NUMERIC',
	YES_NO = 'YES_NO',
	SLIDER_4 = 'SLIDER_4',
	SLIDER_8 = 'SLIDER_8'
}

export enum VEHICLE_SEARCH_TYPE {
    PARC_NUMBER = 'PARC_NUMBER',
    NUMBERPLATE = 'NUMBERPLATE'
}

export enum STEP_KEY {
    SEARCH,
    CLIENT_MOVEMENT,
    CABIN,
    OUTSIDE_STATE,
    TOOLS,
    DRIVER,
    SIGNATURES
}
