import { Radio, RadioProps } from '@material-ui/core'
import { FieldValidator } from 'final-form'
import React from 'react'
import { ReactNode } from 'react'
import { Field } from 'react-final-form'
import './radio.css'

type Props = {
    children?: ReactNode
    name: string
    validate?: FieldValidator<any> | undefined
    img?: string
} & RadioProps

const RivRadio = ({ children, name, validate, img, ...rest }: Props) => {
    return (
        <Field name={name} validate={validate} type="radio" value={rest.value}>
            {({ input }) => (
                <label className={`radio ${input.checked ? 'filled' : ''} ${rest.disabled ? 'disabled' : ''}`}>
                    <Radio
                        {...input}
                        {...rest}
                        size="small"
                        color="primary"
                        edge="start"
                        onChange={(e) => {
                            input.onChange(e)
                            if (rest.onChange) {
                                rest.onChange(e, e.target.checked)
                            }
                        }}
                    />
                    <div className="option-content">
                        <div className="label" >{children}</div>
                        {img && <img className="img" src={img} alt={img} />}
                    </div>
                </label>
            )}
        </Field>
    )
}

export default RivRadio
