import React, { createContext, ReactNode, useEffect, useMemo, useState } from 'react'
import { Build, Gesture, LocalShipping, MoveToInbox, Person, Search, VerifiedUser } from '@material-ui/icons'
import SearchVehicle from './step/search-vehicle/SearchVehicle'
import ClientMovement from './step/client-movement/ClientMovement'
import Driver from './step/driver/Driver'
import OutsideState from './step/outside-state/OutsideState'
import ToolsScreen from './step/tools/ToolsScreen'
import Signatures from './step/signatures/Signatures'
import { BOOLEAN, MEDIA_TYPE, RIV_STEP_TYPE, STEP_KEY, STEP_STATE, TOOLS_TYPE, ZONE_CODE } from '../../utils/enums'
import { loadClientMovement, loadDriver, loadSignature, loadTools, loadVehicleState, saveClientMovement, saveDriver, saveSearchVehicle, saveSignature, saveTools, saveVehicleState } from '../../services/save'
import { useHistory } from 'react-router'
import { getCgu, getRivStep, postData, putData, putMedia } from '../../services/api'
import { LOCAL_STORAGE_ITEM, MEDIA_MAX_RETRY } from '../../utils/constants'
import { useIntl } from 'react-intl'
import { isOnline } from '../../utils/platformUtils'

type StepperState = {
	activeStep: number
	setActiveStep?: React.Dispatch<React.SetStateAction<number>>
	stepsInfo: StepInfo[]
	refresh: () => void
	handleUploadRiv: () => void
	currentStepInfo: StepInfo
	rivInfo: RivInfo
	pristine: boolean
	setPristine?: React.Dispatch<React.SetStateAction<boolean>>
	uploadRivInfos: any[]
	uploadFilesInfos: UploadFilesInfo[]
	uploadStateInfo: UploadStateInfo
	setUploadStateInfo?: React.Dispatch<React.SetStateAction<UploadStateInfo>>
	setTools?: React.Dispatch<React.SetStateAction<Tools | undefined>>
	tools: Tools | undefined
	snackbarInfo: SnackbarInfo
	setSnackbarInfo?: React.Dispatch<React.SetStateAction<SnackbarInfo>>
	cguInfo?: CguInfo
}

const initDataSignature = { driverIsPresent: BOOLEAN.TRUE, clientEmails: [''] }

const steps: StepInfo[] = [
	{
		key: STEP_KEY.SEARCH, icon: <Search fontSize="small" />, state: STEP_STATE.IN_PROGRESS, title: 'home.stepper.step.search_vehicle', content: <SearchVehicle />, initialData: {}, data: {}, getSaveObject: saveSearchVehicle, loadSaveData: () => {
		}
	},
	{
		key: STEP_KEY.CLIENT_MOVEMENT,
		icon: <VerifiedUser fontSize="small" />,
		state: STEP_STATE.TODO,
		title: 'home.stepper.step.client_movement',
		content: <ClientMovement />,
		initialData: {},
		data: {},
		getSaveObject: saveClientMovement,
		loadSaveData: loadClientMovement
	},
	{
		key: STEP_KEY.CABIN,
		icon: <MoveToInbox fontSize="small" />,
		state: STEP_STATE.TODO,
		title: 'home.stepper.step.cabin',
		content: <ToolsScreen toolType={TOOLS_TYPE.CABIN} />,
		initialData: {},
		data: {},
		getSaveObject: saveTools,
		loadSaveData: loadTools
	},
	{
		key: STEP_KEY.OUTSIDE_STATE,
		icon: <LocalShipping fontSize="small" />,
		state: STEP_STATE.TODO,
		title: 'home.stepper.step.outside_state',
		content: <OutsideState />,
		initialData: {},
		data: {},
		getSaveObject: saveVehicleState,
		loadSaveData: loadVehicleState
	},
	{
		key: STEP_KEY.TOOLS,
		icon: <Build fontSize="small" />,
		state: STEP_STATE.TODO,
		title: 'home.stepper.step.tools',
		content: <ToolsScreen toolType={TOOLS_TYPE.EQUIPMENT} />,
		initialData: {},
		data: {},
		getSaveObject: saveTools,
		loadSaveData: loadTools
	},
	{ key: STEP_KEY.DRIVER, icon: <Person fontSize="small" />, state: STEP_STATE.TODO, title: 'home.stepper.step.driver', content: <Driver />, initialData: {}, data: {}, getSaveObject: saveDriver, loadSaveData: loadDriver },
	{
		key: STEP_KEY.SIGNATURES,
		icon: <Gesture fontSize="small" />,
		state: STEP_STATE.TODO,
		title: 'home.stepper.step.signatures',
		content: <Signatures />,
		initialData: { values: initDataSignature },
		data: { values: initDataSignature },
		getSaveObject: saveSignature,
		loadSaveData: loadSignature
	}
]

export const StepperContext = createContext<StepperState>({
	activeStep: 0,
	stepsInfo: steps,
	currentStepInfo: steps[0],
	refresh: () => {
	},
	handleUploadRiv: () => {
	},
	rivInfo: { draft: false, type: RIV_STEP_TYPE.GO, zoneCode: ZONE_CODE.EU },
	pristine: true,
	uploadFilesInfos: [],
	uploadRivInfos: [],
	uploadStateInfo: { isUploading: false, progression: 0 },
	tools: undefined,
	snackbarInfo: { open: false, label: '' }
})

type Props = {
	children: ReactNode
}

export const StepperProvider = ({ children }: Props) => {
	const intl = useIntl()
	const [activeStep, setActiveStep] = useState(0)
	const [stepsInfo, setStepsInfo] = useState<StepInfo[]>(steps)
	const [rivInfo] = useState<RivInfo>({
		draft: false,
		type: RIV_STEP_TYPE.GO,
		zoneCode: localStorage.getItem(LOCAL_STORAGE_ITEM.ZONE) || ZONE_CODE.EU
	})
	const [pristine, setPristine] = useState(true)
	const [uploadFilesInfos] = useState<UploadFilesInfo[]>([])
	const [uploadRivInfos, setUploadRivInfos] = useState<UploadRivInfo[]>([])
	const [uploadStateInfo, setUploadStateInfo] = useState<UploadStateInfo>({ isUploading: false, progression: 0 })
	const [snackbarInfo, setSnackbarInfo] = useState<SnackbarInfo>({ open: false, label: '' })
	const [cguInfo, setCguInfo] = useState<CguInfo>()
	const history = useHistory()
	const currentStepInfo = useMemo(() => stepsInfo[activeStep], [stepsInfo, activeStep])
	const [tools, setTools] = useState<Tools | undefined>(undefined)

	const refresh = () => {
		setStepsInfo([...steps])
	}

	const changeStatus = async () => {
		/**
		 * A chaque changement de statut du reseau
		 * On check si on est co et si on est pas deja en upload, pas de useState, c'est le context qui drive
		 */
		if (await isOnline() && !uploadStateInfo.isUploading) {
			handleUploadRiv().then(() => handleUploadFile())
		}
	}

	useEffect(() => {
		window.addEventListener('online', changeStatus)
		window.addEventListener('offline', changeStatus)

		return () => {
			window.removeEventListener('online', changeStatus)
			window.removeEventListener('offline', changeStatus)
		}
	})

	// Interception si les préférences n'ont jamais été set
	useEffect(() => {
		const zone = localStorage.getItem(LOCAL_STORAGE_ITEM.ZONE)
		const language = localStorage.getItem(LOCAL_STORAGE_ITEM.LANGUAGE)

		if (!zone || !language) {
			history.push('/preferences')
		}
	}, [history])


	// Interception si les CGUs n'ont pas été validées
	useEffect(() => {
		const initCgu = async (lang: string) => {
			const cgu = await getCgu(lang)
			setCguInfo(cgu)
			const cguVersion = Number(localStorage.getItem(LOCAL_STORAGE_ITEM.CGU_VERSION))
			if (isNaN(cguVersion) || !cguVersion || cguVersion < cgu.version) {
				history.push('/cgu')
			}
		}
		if (localStorage.getItem(LOCAL_STORAGE_ITEM.LANGUAGE)) {
			initCgu(intl.locale)
		}
	}, [history, intl])

	useEffect(() => {
		if (tools) {
			steps[2].state = tools[TOOLS_TYPE.CABIN] && tools[TOOLS_TYPE.CABIN].length > 0 ? STEP_STATE.TODO : STEP_STATE.DISABLED
			steps[4].state = tools[TOOLS_TYPE.EQUIPMENT] && tools[TOOLS_TYPE.EQUIPMENT].length > 0 ? STEP_STATE.TODO : STEP_STATE.DISABLED
			const inProgressStepIndex = steps.findIndex((stepInfo) => stepInfo.state === STEP_STATE.TODO || stepInfo.state === STEP_STATE.IN_PROGRESS)
			if (inProgressStepIndex !== -1) {
				steps[inProgressStepIndex].state = STEP_STATE.IN_PROGRESS
			}
			setStepsInfo([...steps])
		}
	}, [tools])

	const handleUploadRiv = async () => {
		if (setUploadStateInfo) {
			setUploadStateInfo((prev) => ({ ...prev, isUploading: true }))

			// Si j'ai des rivs à upload et si je suis connecté à internet
			while (uploadRivInfos.some((uploadRivInfo) => uploadRivInfo.uploadState === STEP_STATE.TODO) && await isOnline()) {
				for (let i = 0; i < uploadRivInfos.length; i++) {
					setUploadStateInfo((prev) => ({ ...prev, isUploading: true }))
					const uploadRivInfo = uploadRivInfos[i]

					if (uploadRivInfo.uploadState === STEP_STATE.TODO) {
						uploadRivInfo.uploadState = STEP_STATE.IN_PROGRESS
						// gestion erreur
						let uploadInError = true
						let nbRetry = 0
						while (uploadInError) {
							try {
								const saveInfo = uploadRivInfo.draft ? await putData(uploadRivInfo.dataToSave) : await postData(uploadRivInfo.dataToSave)
								if (saveInfo && saveInfo.rivStep) {
									uploadRivInfo.uploadState = STEP_STATE.DONE
									uploadInError = false
									setUploadRivInfos((prev) => {
										const newArray = [...prev]
										newArray.splice(i, 1)
										return newArray
									})
									setUploadStateInfo((prev) => ({ ...prev, isUploading: false }))
									await queueFileToUpload(saveInfo, uploadRivInfo.medias)
								} else {
									// Si on a pas de result (retour) on considère que c'est la requete est tombée en erreur
									if (nbRetry < MEDIA_MAX_RETRY) {
										nbRetry++
									} else {
										// on a attend le max de retry autorisé, ca ne sert a rien de continuer
										break
									}
								}
							} catch (e) {
								if (nbRetry < MEDIA_MAX_RETRY) {
									nbRetry++
								} else {
									break
								}
							}
						}

						if (uploadInError || !await isOnline()) {
							// on marque cette step a refaire
							uploadRivInfo.uploadState = STEP_STATE.TODO
							// on stop le tout
							break
						}
					}
				}
			}
			setUploadStateInfo((prev) => ({ ...prev, isUploading: false }))
		}
	}

	const queueFileToUpload = async (saveInfo: SaveInfo, medias: MediaInfo[]) => {
		// On sort les médias pour avoir les signatures en premier
		const sortedMedias: MediaInfo[] = []
		// Stock les ids media  attribués
		const usedDestinationId: string[] = []
		const filteredMedias = saveInfo.rivMedias.filter(rivMedia => !rivMedia.url)
		medias.forEach(media => {
			const rivMedia = filteredMedias.find(rivMedia => !usedDestinationId.includes(rivMedia.id) && rivMedia.type === media.type)
			if (rivMedia) {
				// si on a un slot de dispo on le set a notre media
				media.destinationId = rivMedia && rivMedia.id
				// on marque l'id used
				usedDestinationId.push(rivMedia.id)
			}

			if (media.type === MEDIA_TYPE.CUSTOMER_SIGNATURE || media.type === MEDIA_TYPE.RENTER_SIGNATURE) {
				sortedMedias.unshift(media)
			} else {
				sortedMedias.push(media)
			}
		})

		uploadFilesInfos.push({
			idRivStep: saveInfo.rivStep.id,
			parcNumber: saveInfo.riv.parcNumber,
			plateNumber: saveInfo.riv.plateNumber,
			files: sortedMedias,
			uploadState: STEP_STATE.TODO
		})
		if (!uploadStateInfo.isUploading) {
			await handleUploadFile()
		}
	}

	const handleUploadFile = async () => {
		if (setUploadStateInfo) {
			setUploadStateInfo((prev) => ({ ...prev, isUploading: true }))

			// Si j'ai des média à upload et si je suis connecté à internet
			while (uploadFilesInfos.some((uploadFilesInfo) => uploadFilesInfo.uploadState === STEP_STATE.TODO) && await isOnline()) {
				for (let i = 0; i < uploadFilesInfos.length; i++) {
					const uploadFilesInfo = uploadFilesInfos[i]

					if (uploadFilesInfo.uploadState === STEP_STATE.TODO) {

						const rivStep = await getRivStep(uploadFilesInfo.idRivStep)
						if (rivStep && rivStep.generated) {
							/**
							 * le Riv est deja généré
							 * on passe notre step à DONE
							 **/
							uploadFilesInfo.uploadState = STEP_STATE.DONE
						} else {
							uploadFilesInfo.uploadState = STEP_STATE.IN_PROGRESS

							const files = uploadFilesInfo.files

							// gestion erreur
							let uploadInError = false
							let nbRetry = 0

							for (let j = 0; j < files.length;) {
								setUploadStateInfo((prev) => ({ ...prev, progression: j }))

								const result = await putMedia(uploadFilesInfo.idRivStep, files[j].type, files[j].file, files[j].destinationId)
								if (!result) {
									// Si on a pas de result (retour) on considère que c'est la requete est tombée en erreur
									if (nbRetry < MEDIA_MAX_RETRY) {
										nbRetry++
									} else {
										// on a attend le max de retry autorisé, ca ne sert a rien de continuer, si une image tombe en erreur, il faut recommencer toute la step
										uploadInError = true
										break
									}
								} else {
									// on un result on passe au suivant
									j++
								}
							}

							if (!uploadInError) {
								uploadFilesInfo.uploadState = STEP_STATE.DONE
							} else if (uploadInError || !await isOnline()) {
								// on marque cette step a refaire
								uploadFilesInfo.uploadState = STEP_STATE.TODO
								// on stop le tout
								break
							}
						}
					}
				}
				setUploadStateInfo((prev) => ({ ...prev, progression: 0 }))
			}
			setUploadStateInfo((prev) => ({ ...prev, isUploading: false }))
		}
	}

	return (
		<StepperContext.Provider
			value={{
				activeStep,
				setActiveStep,
				stepsInfo,
				currentStepInfo,
				refresh,
				handleUploadRiv,
				rivInfo,
				pristine,
				setPristine,
				uploadFilesInfos,
				uploadRivInfos,
				uploadStateInfo,
				setUploadStateInfo,
				setTools,
				tools,
				snackbarInfo,
				setSnackbarInfo,
				cguInfo
			}}
		>
			{children}
		</StepperContext.Provider>
	)
}
