import React from 'react'
import { FieldMetaState } from 'react-final-form'
import './error-message.css'
import TranslatedMessage from '../../../modules/translation/TranslatedMessage'

type Props = {
	meta: FieldMetaState<any>
}

const ErrorMessage = ({ meta }: Props) => {
	if (meta.error && meta.touched) {
		return <p className="error"><TranslatedMessage id={meta.error} defaultMessage="Error" description="Error" /></p>
	}

	return <></>
}

export default ErrorMessage
