import React from 'react'
import { FieldValidator } from 'final-form'
import { Field } from 'react-final-form'
import './textarea.css'
import TranslatedMessage from '../../../modules/translation/TranslatedMessage'
import ErrorMessage from '../error-message/ErrorMessage'

type Props = {
	label?: string
	id?: string
	name: string
	validate?: FieldValidator<any> | undefined
} & React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>

const Textarea = ({ label, id, name, validate, ...rest }: Props) => {
	return (
		<Field name={name} validate={validate}>
			{({ input, meta }) => (
				<div className={`textarea ${meta.error ? 'error' : ''}`}>
					{label && <label htmlFor={id}><TranslatedMessage id={label} defaultMessage="Label" description="Input label" /></label>}
					<textarea {...input} {...rest} id={id} />
					{meta.error && meta.touched && <p><ErrorMessage meta={meta} /></p>}
				</div>
			)}
		</Field>
	)
}

export default Textarea
