import { DialogProps, Typography } from '@material-ui/core'
import React from 'react'
import { LargeButton } from '../../../components/buttons/Buttons'
import { RivDialog, RivDialogActions } from '../DialogStyles'
import TranslatedMessage from '../../translation/TranslatedMessage'

type Props = {
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
	contentLabel: string
	onLeave?: () => void
} & DialogProps

const SimpleWarningDialog = ({ open, setOpen, contentLabel, onLeave }: Props) => {
	const handleClose = () => {
		if (onLeave) {
			onLeave()
		}
		setOpen(false)
	}

	return (
		<RivDialog open={open} onClose={handleClose} label="outside_state.dialog.warning.title">
			<Typography variant="body1" component="p">
				<TranslatedMessage id={contentLabel} defaultMessage="Action à risque" description="Warning text" />
			</Typography>
			<RivDialogActions>
				<LargeButton className="full-width" onClick={handleClose}>
					<TranslatedMessage id="search_vehicle.dialog.modify.confirm" defaultMessage="Continuer" description="Confirm button" />
				</LargeButton>
			</RivDialogActions>
		</RivDialog>
	)
}

export default SimpleWarningDialog
