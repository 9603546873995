import { Checkbox, CheckboxProps } from '@material-ui/core'
import { FieldValidator } from 'final-form'
import React from 'react'
import { ReactNode } from 'react'
import { Field } from 'react-final-form'
import ErrorMessage from '../error-message/ErrorMessage'
import './checkbox.css'

type Props = {
    children?: ReactNode
    name: string
    validate?: FieldValidator<any> | undefined
} & CheckboxProps

const RivCheckbox = ({ children, name, validate, ...rest }: Props) => {
    return (
        <Field name={name} validate={validate} type="checkbox">
            {({ input, meta }) => (
                <div>
                    <label className={`checkbox ${input.checked && 'filled'} ${meta.error ? 'error' : ''} ${rest.disabled ? 'disabled' : ''}`}>
                        <Checkbox {...input} {...rest} color="primary" edge="start" />
                        <div className="label">{children}</div>
                    </label>
                    <ErrorMessage meta={meta} />
                </div>
            )}
        </Field>
    )
}

export default RivCheckbox
