import { Typography } from '@material-ui/core'
import React, { useContext } from 'react'
import { LargeButton } from '../../../components/buttons/Buttons'
import { RIV_STEP_TYPE, STEP_STATE } from '../../../utils/enums'
import { StepperContext } from '../../stepper/StepperContext'
import { RivDialog, RivDialogActions } from '../DialogStyles'
import TranslatedMessage from '../../translation/TranslatedMessage'
import { resetRivSteps } from '../../../services/save'

type Props = {
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
	onBack: () => void
}

const ModifyDialog = ({ open, setOpen, onBack }: Props) => {
	const { currentStepInfo, activeStep, stepsInfo, refresh, rivInfo } = useContext(StepperContext)

	const handleClose = () => {
		setOpen(false)
	}

	const handleConfirm = () => {
		resetRivSteps(stepsInfo, activeStep)
		currentStepInfo.state = STEP_STATE.IN_PROGRESS
		rivInfo.draft = false
		if (rivInfo.type === RIV_STEP_TYPE.BACK) {
			onBack()
		}
		refresh()
		handleClose()
	}

	return (
		<RivDialog label="search_vehicle.dialog.modify.title" open={open} onClose={handleClose}>
			<Typography variant="body1" component="p">
				<TranslatedMessage id="search_vehicle.dialog.modify.content" defaultMessage="Les données des étapes suivantes seront supprimées." description="dialog content" />
			</Typography>
			<RivDialogActions>
				<LargeButton className="full-width" onClick={handleClose} variant="outlined">
					<TranslatedMessage id="search_vehicle.dialog.modify.cancel" defaultMessage="Annuler" description="Cancel button" />
				</LargeButton>
				<LargeButton type="submit" className="full-width" onClick={handleConfirm}>
					<TranslatedMessage id="search_vehicle.dialog.modify.confirm" defaultMessage="Continuer" description="Confirm button" />
				</LargeButton>
			</RivDialogActions>
		</RivDialog>
	)
}

export default ModifyDialog
