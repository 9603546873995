import { MEDIA_TYPE } from "./enums"

export const colors = {
	primary: '#289B53',
	secondary: '#FF9326',
	secondaryLight: '#FFF2E6',
	black: '#000000',
	grey1: '#999999',
	grey2: '#F0F2F1',
	gaugeSeparator: '#ECEFED'
}

export const minWidth = '(min-width:1080px)'

export const FACE_KEY_NAME = 'face'

export const FACE_DIRECTION = {
	FR: ['left', 'front', 'right', 'back', 'inside'],
	UK: ['inside', 'back', 'right', 'front', 'left']
}

export const DAMAGE_FACE_ENUM = [MEDIA_TYPE.FACE1, MEDIA_TYPE.FACE2, MEDIA_TYPE.FACE3, MEDIA_TYPE.FACE4, MEDIA_TYPE.FACE5]

export const gaugeTypes = {
	medium: ['E/0', '1/4', '1/2', '3/4', 'F'],
	big: ['E/0', '1/8', '1/4', '3/8', '1/2', '5/8', '3/4', '7/8', 'F']
}

export const alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J']

export const picturesKey = ['loadedPictures', 'deletedPicturesId', 'pictures', 'cabinComment', 'equipmentComment']

export const LOCAL_STORAGE_ITEM = {
	CGU_VERSION: 'cgu_version',
	LANGUAGE: 'language',
	ZONE: 'zone'
}

export const MEDIA_TIME_OUT = 20
export const MEDIA_MAX_RETRY = 3
