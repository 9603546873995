import { Button, ButtonProps, withStyles } from '@material-ui/core'
import React from 'react'
import './buttons.css'

export const MediumButton = withStyles(() => ({
    root: {
        fontSize: 15,
        textTransform: 'none',
        borderRadius: 6,
        height: 44,
        padding: '0 20px',
        borderWidth: 2,
    },
}))((props: ButtonProps & { component?: any }) => (
    <Button color="primary" variant="contained" disableElevation {...props}>
        {props.children}
    </Button>
))

export const SecondaryButton = withStyles(() => ({
    root: {
        fontSize: 18,
        textTransform: 'none',
        borderRadius: 6,
        height: 64,
        padding: '0 20px',
        borderWidth: 2,
    },
}))((props: ButtonProps & { component?: any }) => (
    <Button color="primary" variant="outlined" disableElevation {...props}>
        {props.children}
    </Button>
))

export const LargeButton = withStyles(() => ({
    root: {
        fontSize: 18,
        textTransform: 'none',
        borderRadius: 6,
        height: 64,
        padding: '0 20px',
        borderWidth: 2,
    },
}))((props: ButtonProps & { component?: any }) => (
    <Button color="primary" variant="contained" disableElevation {...props}>
        {props.children}
    </Button>
))

export const ResetButton = withStyles(() => ({
    root: {
        fontSize: 15,
        letterSpacing: 0.45
    }
}))((props: ButtonProps) => (
    <Button color="primary" disableElevation {...props}>
        {props.children}
    </Button>
))
