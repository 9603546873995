import { Typography } from '@material-ui/core'
import React, { useContext } from 'react'
import { LargeButton, SecondaryButton } from '../../../components/buttons/Buttons'
import { STEP_STATE } from '../../../utils/enums'
import { StepperContext } from '../../stepper/StepperContext'
import { RivDialog, RivDialogActions } from '../DialogStyles'
import './synchronize-dialog.css'
import TranslatedMessage from '../../translation/TranslatedMessage'

type Props = {
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const SynchronizeDialog = ({ open, setOpen }: Props) => {
	const { uploadFilesInfos, uploadStateInfo, uploadRivInfos, handleUploadRiv } = useContext(StepperContext)

	const hasWaitingUploads = uploadFilesInfos.some(f => f.uploadState !== STEP_STATE.DONE) ||
		uploadRivInfos.some(f => f.uploadState !== STEP_STATE.DONE)

	const handleClose = () => setOpen(false)

	const getMessage = (uploadState: number, isRiv = false) => {
		switch (uploadState) {
			case STEP_STATE.TODO:
				return 'todo'
			case STEP_STATE.IN_PROGRESS:
				return isRiv ? 'in_progress_riv' : 'in_progress'
			default:
				return 'done'
		}
	}

	return (
		<RivDialog open={open} onClose={handleClose} label="home.stepper.dialog.synchronize.title">
			<Typography variant="body1" component="p">
				<TranslatedMessage id="home.stepper.dialog.synchronize.content" defaultMessage="En sauvegardant un brouillon, vous allez quittez la saisie de ce RIV." description="dialog content" />
			</Typography>
			<table className="synchronize">
				<tbody>
				{uploadRivInfos.map((uploadRivInfo, i) => (
					<tr key={i}>
						<Typography variant="body1" component="td">
							{uploadRivInfo.dataToSave.parcNumber}
						</Typography>
						<Typography variant="body1" component="td" className="align-right">
							<TranslatedMessage
								id={`home.stepper.dialog.synchronize.${getMessage(uploadRivInfo.uploadState, true)}`}
								defaultMessage="Terminé"
								values={uploadRivInfo.uploadState === STEP_STATE.IN_PROGRESS ? { num: uploadStateInfo.progression, total: (uploadRivInfo.files || []).length } : {}}
								description="RIV Upload State"
							/>
						</Typography>
					</tr>
				))}
				{uploadFilesInfos.map((uploadFilesInfo, i) => (
					<tr key={i}>
						<Typography variant="body1" component="td">
							{uploadFilesInfo.parcNumber}
						</Typography>
						<Typography variant="body1" component="td" className="align-right">
							<TranslatedMessage
								id={`home.stepper.dialog.synchronize.${getMessage(uploadFilesInfo.uploadState)}`}
								defaultMessage="Terminé"
								values={uploadFilesInfo.uploadState === STEP_STATE.IN_PROGRESS ? { num: uploadStateInfo.progression, total: uploadFilesInfo.files.length } : {}}
								description="File Upload State"
							/>
						</Typography>
					</tr>
				))}
				</tbody>
			</table>
			{(hasWaitingUploads && !uploadStateInfo.isUploading) &&
				<SecondaryButton
					size="small"
					onClick={handleUploadRiv}
				>
					<TranslatedMessage id="home.stepper.dialog.synchronize.resynchronize" defaultMessage="Resynchroniser" description="Resynchronize button" />
				</SecondaryButton>
			}
			<RivDialogActions>
				<LargeButton type="submit" className="full-width" onClick={handleClose}>
					<TranslatedMessage id="home.stepper.dialog.synchronize.close" defaultMessage="Fermer" description="Close button" />
				</LargeButton>
			</RivDialogActions>
		</RivDialog>
	)
}

export default SynchronizeDialog
