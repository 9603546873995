import React from 'react'
import { useContext, useEffect } from 'react'
import { StepperContext } from './StepperContext'

type Props = {
    pristine: boolean
}

const PristineListener = ({ pristine }: Props) => {
    const { setPristine } = useContext(StepperContext)

    useEffect(() => {
        if (setPristine) {
            setPristine(pristine)
        }
        return () => {
            if (setPristine) {
                setPristine(true)
            }
        }
    }, [pristine, setPristine])

    return <></>
}

export default PristineListener
