import { DialogProps, Typography } from '@material-ui/core'
import React from 'react'
import { LargeButton } from '../../../components/buttons/Buttons'
import { RivDialog, RivDialogActions } from '../DialogStyles'
import TranslatedMessage from '../../translation/TranslatedMessage'

type Props = {
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
	onConfirm: () => void
	contentLabel: string
	num?: string
} & DialogProps

const DeleteImpactDialog = ({ open, setOpen, onConfirm, contentLabel, num }: Props) => {
	const handleClose = () => {
		setOpen(false)
	}

	const handleClick = () => {
		onConfirm()
		handleClose()
	}

	return (
		<RivDialog open={open} onClose={handleClose} label="outside_state.dialog.delete_all.title">
			<Typography variant="body1" component="p">
				<TranslatedMessage id={contentLabel} defaultMessage="Voulez-vous supprimer les dégâts déjà saisis ?" description="Delete all impact points" values={{ num }} />
			</Typography>
			<RivDialogActions>
				<LargeButton className="full-width" onClick={handleClick}>
					<TranslatedMessage id="search_vehicle.dialog.modify.confirm" defaultMessage="Continuer" description="Confirm button" />
				</LargeButton>
			</RivDialogActions>
		</RivDialog>
	)
}

export default DeleteImpactDialog
