import flatten from 'flat'

interface ModelTranslations {
	[key: string]: string
}

const target = {
	home: {
		title: {
			GENERAL: 'Register an inspection report',
			ALLER: 'Register a departure inspection report',
			RETOUR: 'Register a return inspection report'
		},
		stepper: {
			step: {
				label: 'Step',
				search_vehicle: 'Search a fleet',
				client_movement: 'Customer and movement',
				driver: 'Driver',
				cabin: 'Cabin',
				outside_state: 'State of the fleet',
				tools: 'Equipments',
				signatures: 'Signature'
			},
			save: 'Draft',
			save_tablet: 'Save draft',
			register: 'Save',
			next: 'Next',
			next_tablet: 'Go to the next step',
			done: 'Complete',
			modify: 'Modify',
			in_progress: 'In Progress',
			disabled: 'Not applicable',
			submit: 'Confirm and send the report',
			dialog: {
				leave: 'All information is not saved. Do you want to quit this step ?',
				save: 'By saving a draft, you will stop the registration of the inspection report.',
				send: 'By sending the report, you will stop the registration of the inspection report. Don’t close the App before all pictures are uploaded.',
				conflict: 'A newer inspection report already exist. Your report will be deleted.',
				synchronize: {
					title: 'Synchronize in progress',
					content: 'Internet connection required to register an inspection report.',
					todo: 'Waiting...',
					in_progress: 'Uploading pictures ({num}/{total})',
					in_progress_riv: 'Uploading RIV',
					done: 'Done',
					close: 'Close'
				}
			},
			snackbar: {
				success: 'Inspection report send',
				save: 'Inspection report saved',
				error: 'An error occured.'
			}
		},
		upload: 'An Inspection Report is currently under proceeding, don\'t close the app.'
	},
	search_vehicle: {
		radios: {
			labels: {
				search_by: 'search by',
				found_two: 'TWO FLEET NUMBERS ARE FIND'
			},
			search_by: {
				parc_number: 'Fleet number',
				numberplate: 'Registration'
			}
		},
		inputs: {
			labels: {
				parc: 'Input fleet number',
				numberplate: 'Input registration',
				other: 'PLEASE SPECIFY'
			}
		},
		info: {
			title: {
				go: 'Registration of a departure inspection report',
				back: 'Registration of a return inspection report'
			},
			parc_number: 'Fleet Number',
			numberplate: 'REGISTRATION',
			agence: 'BRANCH',
			category: 'CATEGORY',
			model: 'MODEL'
		},
		button_model: 'Select a model',
		modify_model: 'Modify the model',
		no_match: 'No result',
		dialog: {
			model_selection: {
				title: 'Select a model',
				select: 'Select a model to register a inspection report',
				next: 'Next'
			},
			modify: {
				title: 'Warning !',
				content: 'All informations of nexts steps will be deleted',
				confirm: 'Confirm',
				cancel: 'Cancel'
			},
			load_save: {
				title: {
					go: 'Departure Inspection report saved',
					back: 'Return Inspection report saved'
				},
				content: 'An inspection report is already saved for this fleet on the {date} at {time}.',
				warning: 'Warning, in case of a new registration all informations already saved will be deleted.',
				label: 'Continue the inspection report',
				load: 'Back on saved inspection report',
				delete: 'Register a new report'
			},
			media_missing: 'A Saved Inspection Report has some missing medias. Do you want to delete this report ?',
			is_sending: 'An Inspection Report is currently being uploaded for this fleet. Please try again later.'
		}
	},
	outside_state: {
		title: {
			select_face: 'SELECT A SIDE',
			create_points: 'Add points on the picture',
			face_state: 'state of the side',
			face: {
				left: 'Left Side',
				front: 'Front',
				right: 'Right Side',
				back: 'Back',
				inside: 'Behind doors opened'
			},
			face_picture: 'SIDE\'S PICTURE'
		},
		info: {
			picture: 'At least you need to add one picture',
			impact: 'Click on the picture to add damages'
		},
		radios: {
			damage: 'Damages',
			nothing: 'Nothing to report'
		},
		buttons: {
			picture: 'Take a picture',
			another_picture: 'Take another picture'
		},
		comment: 'Commentaire',
		dialog: {
			create: {
				title: 'Add a damage',
				radio: {
					label: 'Type of damage',
					scratch: 'Scratch',
					blow: 'Blow',
					broken: 'Broken'
				}
			},
			delete_all: {
				title: 'Warning !',
				content: 'Do you want to delete all damages added ?'
			},
			delete_one: {
				title: 'Warning !',
				content: 'Do you want to delete the damage {num} on this side ?',
				content_previous_back: 'The damage {num} was already on previous RIV. Do you want to delete the damage ?'
			},
			delete_picture: {
				title: 'Warning !',
				content: 'Do you want to delete this picture ?'
			},
			warning: {
				title: 'Warning !',
				content: 'You cannot add more than 10 Damages.'
			}
		}
	},
	client_movement: {
		radios: {
			labels: {
				movement_type: 'TYPE OF MOVEMENT'
			}
		},
		inputs: {
			client_name: 'CLIENT NAME'
		}
	},
	driver: {
		radios: {
			driver_info: {
				label: 'Driver\'s Informations',
				manual: 'Complete manually',
				none: 'No Driver'
			}
		},
		inputs: {
			firstname: 'DRIVER\'S FIRST NAME',
			lastname: 'DRIVER\'S NAME',
			license_number: 'Driver licence number',
			valid_date: 'Validity date',
			age: 'Driver minimum aged of 21 Years old',
			year: 'Driving licence valid since more than 1 year',
			category: 'Driving licence corresponding to the weight and category of the rentaled fleet'
		}
	},
	signatures: {
		radios: {
			labels: {
				send_mail_to_client: 'SEND THE REPORT TO THE CUSTOMER ?',
				driver_is_present: 'PRESENCE OF THE CUSTOMER AND/OR DRIVER ?'
			},
			values: {
				yes: 'Yes',
				no: 'No'
			}
		},
		inputs: {
			client_email: 'EMAIL ADDRESS',
			driver_firstname: 'FIRST NAME OF THE CUSTOMER AND/OR DRIVER',
			driver_lastname: 'NAME OF THE CUSTOMER AND/OR DRIVER',
			renter_firstname: 'FIRST NAME OF THE RENTER',
			renter_lastname: 'NAME OF THE RENTER'
		},
		checkbox: {
			driver_authorization: 'The customer and/or driver agree to the transmission of all information.',
			driver_concern: 'Customer notified of all operation information of the vehicle and specific information (height, length).'
		},
		signature: {
			driver: 'Signature of the customer and/or driver',
			renter: 'Signature of the renter'
		},
		reset: 'Erase'
	},
	tools: {
		radios: {
			values: {
				yes: 'Yes',
				no: 'No',
				notConcerned: 'Not concerned'
			}
		},
		comment: 'COMMENT',
		notMandatory: '- NOT MANDATORY'
	},
	form: {
		gauges: {
			selected_value: 'Selected : {value}'
		},
		pictures: 'CABIN\'S PICTURES'
	},
	errors: {
		form: {
			required: 'Required field',
			futureDate: 'Future date required',
			pictureRequired: 'Pictures required',
			emailFormat: 'Wrong Email Address'
		},
		network: 'Technical issue appear'
	},
	serviceWorker: {
		addApp: {
			title: 'Add application on home',
			btn: 'Add'
		},
		update: {
			title: 'New version available',
			description: 'A new version is available, you must update your version to use this application.',
			btn: 'Update version'
		}
	},
	cgu: {
		title: 'Terms and conditions',
		checkbox: 'I accept all terms and condidtions',
		validate: 'Confirm'
	},
	preferences: {
		title: 'Yours preferences',
		language: 'Select a language',
		zone: 'Select a geographic zone'
	},
	enums: {
		language: {
			fr: 'Français',
			en: 'English',
			es: 'Spanish',
			at: 'Austrian',
			cz: 'Czech',
			de: 'German',
			hu: 'Hungarian',
			it: 'Italian',
			pl: 'Polish',
			pt: 'Portuguese',
			us: 'American'
		},
		zone: {
			EU: 'Europe',
			UK: 'UK',
			US: 'US'
		},
		movement_type: {
			ald: 'Relais/ALD',
			cd: 'CD',
			cdm: 'CDM',
			ld: 'LD',
			at_ext: 'External Workshop',
			trans: 'Branch transit',
			other: 'Other'
		},
		movement_comment: {
			ald: 'PARC NUMBER',
			other: 'JUSTIFY'
		}
	},
	dialog: {
		network: {
			warning: {
				title: 'Warning !',
				content: 'A Network connection is required in order to send the data.',
				modify: {
					cancel: 'Cancel',
					retry: 'Retry'
				}
			}
		}
	}
}


export default flatten<typeof target, ModelTranslations>(target)
