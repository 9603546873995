import React, { useContext, useState } from 'react'
import { Grid, IconButton } from '@material-ui/core'
import StepperLayout from '../stepper/Stepper'
import Header from '../../components/header/Header'
import { LocalShippingOutlined, SettingsOutlined, Sync } from '@material-ui/icons'
import { StepperContext } from '../stepper/StepperContext'
import SynchronizeDialog from '../dialog/synchronize/SynchronizeDialog'
import RivSnackbar from '../../components/snackbar/Snackbar'
import { STEP_STATE } from '../../utils/enums'
import { useHistory } from 'react-router'
import TranslatedMessage from '../translation/TranslatedMessage'

const Homepage = () => {
	const history = useHistory()
	const { uploadFilesInfos, uploadRivInfos, rivInfo, stepsInfo } = useContext(StepperContext)
	const [openSyncDialog, setOpenSyncDialog] = useState(false)
	const uploadWaiting = !!uploadFilesInfos.filter(u => u.uploadState !== STEP_STATE.DONE).length || !!uploadRivInfos.filter(u => u.uploadState !== STEP_STATE.DONE).length

	return (
		<Grid item>
			<Header isUploading={uploadWaiting} openSyncDialog={() => setOpenSyncDialog(true)}>
				<LocalShippingOutlined fontSize="large" />
				<span>
                    <TranslatedMessage id={`home.title.${stepsInfo[0].state !== STEP_STATE.DONE ? 'GENERAL' : rivInfo.type}`} defaultMessage="Saisir un RIV" description="Page Title" />
                </span>
				<div>
					{uploadWaiting && (
						<IconButton className="rotating" onClick={() => setOpenSyncDialog(true)}>
							<Sync />
						</IconButton>
					)}
					<IconButton edge="end" onClick={() => history.push('/preferences')}>
						<SettingsOutlined className="settings" />
					</IconButton>
				</div>
			</Header>
			<StepperLayout />
			<SynchronizeDialog open={openSyncDialog} setOpen={setOpenSyncDialog} />
			<RivSnackbar />
		</Grid>
	)
}

export default Homepage
