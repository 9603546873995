import { IconButton, makeStyles, Snackbar, Theme, Typography } from '@material-ui/core'
import React, { useContext } from 'react'
import { StepperContext } from '../../modules/stepper/StepperContext'
import { Close } from '@material-ui/icons'
import TranslatedMessage from '../../modules/translation/TranslatedMessage'

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		padding: 20,
		paddingTop: 10,
		paddingBottom: 10,
		borderRadius: 4,
		color: 'white',
		display: 'flex',
		alignItems: 'center',
		columnGap: 10,
		'& > p': {
			color: 'white'
		}
	},
	success: {
		backgroundColor: theme.palette.primary.main
	}
}))

const RivSnackbar = () => {
	const classes = useStyles()
	const { snackbarInfo, setSnackbarInfo } = useContext(StepperContext)

	const handleClose = () => {
		if (setSnackbarInfo) {
			setSnackbarInfo((prev) => ({ ...prev, open: false }))
		}
	}

	return (
		<Snackbar open={snackbarInfo.open} autoHideDuration={5000} onClose={handleClose}>
			<div className={`${classes.root} ${classes.success}`}>
				<Typography variant="body1" component="p">
					<TranslatedMessage id={snackbarInfo.label} defaultMessage="RIV envoyé" description="Snackbar message" />
				</Typography>
				<IconButton size="small" aria-label="close" edge="end" color="inherit" onClick={handleClose}>
					<Close fontSize="small" />
				</IconButton>
			</div>
		</Snackbar>
	)
}

export default RivSnackbar
