import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import Bottom from '../../bottom/Bottom'
import { StepperContext } from '../../StepperContext'
import './outside-state.css'
import arrayMutators from 'final-form-arrays'
import { ButtonBase, IconButton, Typography } from '@material-ui/core'
import RadioGroup, { RadioOption } from '../../../../components/field/radio/RadioGroup'
import OutsideStateMultipleFileInput from '../../../../components/field/file-input/OutsideStateMultipleFileInput'
import { alphabet, FACE_DIRECTION, FACE_KEY_NAME } from '../../../../utils/constants'
import CanvasPoints from '../../../../components/canvas-points/CanvasPoints'
import { Close } from '@material-ui/icons'
import DeleteImpactDialog from '../../../dialog/delete-impact/DeleteImpactDialog'
import { FACE_STATE, RIV_STEP_TYPE } from '../../../../utils/enums'
import PristineListener from '../../PristineListener'
import OutsideStatePictures from '../../../../components/field/pictures/OutsideStatePictures'
import Textarea from '../../../../components/field/textarea/Textarea'
import TranslatedMessage from '../../../translation/TranslatedMessage'

const OutsideState = () => {
	const { stepsInfo, currentStepInfo, rivInfo } = useContext(StepperContext)
	const [assetModel, setAssetModel] = useState<AssetModel>()
	const [faceIndex, setFaceIndex] = useState(0)
	const [openDeleteAllDialog, setOpenDeleteAllDialog] = useState(false)
	const [openDeleteOneDialog, setOpenDeleteOneDialog] = useState(false)
	const [selectedRowIndex, setSelectedRowIndex] = useState(0)

	useEffect(() => {
		setAssetModel(stepsInfo[0].data.selectedModel)
	}, [stepsInfo])

	const facesArrayKey = useMemo(
		() =>
			Object.keys(assetModel ?? [])
				.filter((key) => key.includes(FACE_KEY_NAME) && assetModel && assetModel[key] !== null)
				.sort(),
		[assetModel]
	)

	if (!assetModel) {
		return <> </>
	}

	const faceStateOptions: RadioOption[] = [
		{
			label: <TranslatedMessage id="outside_state.radios.damage" defaultMessage="Dégâts" description="Damage" />,
			value: FACE_STATE.IMPACT
		},
		{
			label: <TranslatedMessage id="outside_state.radios.nothing" defaultMessage="Rien à signaler" description="Nothing to report" />,
			value: FACE_STATE.NOTHING
		}
	]

	const validate = (values: OutsideStateForm) => {
		const errors: any = { faces: [] }
		for (let i = 0; i < values.faces.length; i++) {
			const face = values.faces[i]
			const error: any = {}
			if (!face.faceState) {
				error.faceState = 'errors.form.required'
			} else if (face.faceState === FACE_STATE.IMPACT && !face.impacts.length) {
				error.faceState = 'errors.form.required'
			}
			if ((!face.pictures || !face.pictures.length) && !face.loadedPictures.length) {
				error.pictures = ['errors.form.required']
			}
			errors.faces.push(error)
		}
		return errors
	}

	const faceCompleted = (face: OutsideStateFace) => {
		if (!face.faceState || ((!face.pictures || !face.pictures.length) && !face.loadedPictures.length)) {
			return false
		}
		if (face.faceState === FACE_STATE.IMPACT && !face.impacts.length) {
			return false
		}
		return true
	}

	const handleNext = (values: OutsideStateForm) => {
		currentStepInfo.data = { values }
	}

	return (
		<Form
			onSubmit={handleNext}
			mutators={{
				...arrayMutators
			}}
			initialValues={
				currentStepInfo.data.values ?? {
					faces: facesArrayKey.map((face) => ({
						key: face,
						impacts: [],
						loadedPictures: [],
						deletedPicturesId: []
					}))
				}
			}
			validate={validate}
			keepDirtyOnReinitialize
			render={({ handleSubmit, invalid, values, pristine }) => (
				<>
					<PristineListener pristine={pristine} />
					<form className="layout outside-state" onSubmit={handleSubmit} autoComplete="off">
						<FieldArray name="faces">
							{({ fields }) => (
								<>
									<div className="left">
										{/* Select face Tabs */}
										<div className="tab-face flex-col-small">
											<Typography variant="h2" component="p">
												<TranslatedMessage id="outside_state.title.select_face" defaultMessage="SÉLECTIONNEZ UNE FACE" description="Select a face" />
											</Typography>
											<div>
												{fields.value.map((face: OutsideStateFace, i) => (
													<ButtonBase
														key={i}
														className={`face ${faceCompleted(face) && 'completed'} ${
															(i === faceIndex || face.faceState || (face.pictures && face.pictures.length) || face.loadedPictures.length) && 'ongoing'
														}`}
														onClick={() => setFaceIndex(i)}
													>
														<img src={assetModel[`urlFace${i + 1}`]} alt={face.key} />
													</ButtonBase>
												))}
											</div>
										</div>
										{/* Point selection on canvas & face state radio  */}
										{fields.map(
											(name, i) =>
												i === faceIndex && (
													<FieldArray name={`${name}.impacts`} key={i}>
														{({ fields: impactFields }) => (
															<div className="flex-col">
																<RadioGroup
																	name={`${name}.faceState`}
																	onValueChange={(val) => {
																		if (val === FACE_STATE.NOTHING && fields.value[i].impacts.length) {
																			fields.update(i, { ...fields.value[i], faceState: FACE_STATE.IMPACT })
																			setOpenDeleteAllDialog(true)
																		}
																	}}
																	options={faceStateOptions}
																	direction="row"
																	label="outside_state.title.face_state"
																/>
																{values.faces[faceIndex].faceState === FACE_STATE.IMPACT && (
																	<div className="flex-col-small">
																		<Typography variant="h2" component="p">
																			<TranslatedMessage
																				id="outside_state.title.create_points"
																				defaultMessage="SÉLECTIONNEZ UNE FACE"
																				description="Select a face"
																			/>
																		</Typography>
																		<CanvasPoints
																			disabled={fields.value[i].faceState !== FACE_STATE.IMPACT}
																			push={impactFields.push}
																			imagePath={assetModel[`urlFace${i + 1}`]}
																			impacts={impactFields.value}
																			fromGoStep={rivInfo.type === RIV_STEP_TYPE.GO}
																		/>
																	</div>
																)}
																<DeleteImpactDialog
																	open={openDeleteAllDialog}
																	setOpen={setOpenDeleteAllDialog}
																	onConfirm={() => {
																		fields.update(i, { ...fields.value[i], faceState: FACE_STATE.NOTHING })
																		impactFields.forEach((field, i) => impactFields.pop())
																	}}
																	contentLabel="outside_state.dialog.delete_all.content"
																/>
															</div>
														)}
													</FieldArray>
												)
										)}
									</div>
									<div className="right">
										{/* Impact list if damage type is Impact */}
										{fields.map(
											(name, i) =>
												values.faces[faceIndex].faceState === FACE_STATE.IMPACT &&
												i === faceIndex && (
													<div className="flex-col face-dommage" key={i}>
														<Typography variant="h2" component="p">
															<TranslatedMessage id={`outside_state.title.face.${FACE_DIRECTION.FR[faceIndex]}`} defaultMessage="FACE" description="Face" />
														</Typography>
														{!(fields.value[faceIndex].impacts as ImpactInfo[]).length && (
															<Typography variant="body1" component="span">
																<TranslatedMessage
																	id="outside_state.info.impact"
																	defaultMessage="Cliquez sur l'image pour positionner des dégats"
																	description="Info text"
																/>
															</Typography>
														)}
														<FieldArray name={`${name}.impacts`}>
															{({ fields: impactFields }) => (
																<table>
																	<tbody>
																	{(fields.value[faceIndex].impacts as ImpactInfo[]).map((impact, i) => (
																		<tr key={i}>
																			<td className="number">
																				<Typography variant="body1" component="span" color="primary">
																					{alphabet[i]}
																				</Typography>
																			</td>
																			<td className="flex">
																				<Typography variant="body1">
																					<TranslatedMessage
																						id={`outside_state.dialog.create.radio.${impact.type.toLowerCase()}`}
																						defaultMessage="Type impact"
																						description="Impact type"
																					/>
																				</Typography>
																				{(rivInfo.type === RIV_STEP_TYPE.GO || !impact.fromGoStep) && <IconButton
																					size="small"
																					onClick={() => {
																						setSelectedRowIndex(i)
																						setOpenDeleteOneDialog(true)
																					}}
																				>
																					<Close />
																				</IconButton>}
																			</td>
																		</tr>
																	))}
																	</tbody>
																	<DeleteImpactDialog
																		open={openDeleteOneDialog}
																		setOpen={setOpenDeleteOneDialog}
																		contentLabel={
																			fields.value[faceIndex].impacts && fields.value[faceIndex].impacts[selectedRowIndex] && fields.value[faceIndex].impacts[selectedRowIndex].fromPreviousBackStep
																			? 'outside_state.dialog.delete_one.content_previous_back' : 'outside_state.dialog.delete_one.content'
																		}



																		onConfirm={() => impactFields.remove(selectedRowIndex)}
																		num={alphabet[selectedRowIndex]}
																	/>
																</table>
															)}
														</FieldArray>
													</div>
												)
										)}
										{/* Comment */}
										{fields.map((name, i) => (
											<div className={`flex-col ${i !== faceIndex && 'd-none'}`} key={`comment-${i}`}>
												<Textarea name={`${name}.comment`} label="outside_state.comment" id="textarea" rows={4} />
											</div>
										))}
										{/* Multiple picture Input */}
										{fields.map((name, i) => (
											<div className={`flex-col ${i !== faceIndex && 'd-none'}`} key={`picture-${i}`}>
												<Typography variant="h2" component="p">
													<TranslatedMessage id="outside_state.title.face_picture" defaultMessage="PHOTOS DE LA FACE" description="Picture of the face" />
												</Typography>
												<OutsideStateMultipleFileInput
													name={`${name}.pictures`}
													label="outside_state.buttons.picture"
													multipleLabel="outside_state.buttons.another_picture"
													isMultiple={!!fields.value[i].loadedPictures.length}
												/>
												{((!fields.value[i].pictures || !fields.value[i].pictures.length) && !fields.value[i].loadedPictures.length) && (
													<Typography variant="body1" component="span">
														<TranslatedMessage id="outside_state.info.picture" defaultMessage="Vous devez prendre au moins une photo" description="Info text" />
													</Typography>
												)}
												{!!fields.value[i].loadedPictures.length && (
													<OutsideStatePictures deletePicturesName={`${name}.deletedPicturesId`} loadedPicturesName={`${name}.loadedPictures`} />
												)}
											</div>
										))}
									</div>
								</>
							)}
						</FieldArray>
					</form>
					<Bottom disabled={invalid} onSave={() => handleNext(values)} onNext={() => handleNext(values)} />
				</>
			)}
		/>
	)
}

export default OutsideState
