export const b64toBlob = (b64Data: string, contentType: string, sliceSize?: number) => {
    contentType = contentType || ''
    sliceSize = sliceSize || 512

    const byteCharacters = atob(b64Data)
    const byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize)

        const byteNumbers = new Array(slice.length)
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i)
        }

        const byteArray = new Uint8Array(byteNumbers)

        byteArrays.push(byteArray)
    }

    return new Blob(byteArrays, { type: contentType })
}

export const base64ToFile = (b64Data: string, filename: string, mimeType: string) => {
    return new File([b64toBlob(b64Data.split('base64,').pop() ?? '', mimeType)], filename, { type: mimeType })
}
