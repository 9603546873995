import React from 'react'
import { IntlProvider } from 'react-intl'
import messagesEn from './messages_en'
import messagesFr from './messages_fr'
import { LANGUAGE_CODE } from '../../utils/enums'
import { LOCAL_STORAGE_ITEM } from '../../utils/constants'

type MessageProps = {
	fr: any
	en: any
}
const MESSAGES: MessageProps = {
	fr: messagesFr,
	en: messagesEn
}

type RivIntlContextProps = {
	changeLanguage: (code: string) => void
}
type Props = {
	children: any
}
type IntlState = {
	locale: string
}

export const RivIntlContext = React.createContext<RivIntlContextProps>({ changeLanguage: () => {} })

export const IntlProviderWrapper = ({ children }: Props) => {
	const [intl, setIntl] = React.useState<IntlState>({
		locale: localStorage.getItem(LOCAL_STORAGE_ITEM.LANGUAGE) || ''
	})

	const changeLanguage = (code: string) => {
		setIntl({
			locale: code
		})
	}

	const contextValues = {
		changeLanguage
	}

	return <RivIntlContext.Provider value={contextValues}>
		<IntlProvider
			locale={intl.locale}
			key={intl.locale}
			messages={MESSAGES[intl.locale as keyof typeof MESSAGES] || MESSAGES[LANGUAGE_CODE.FR]}
		>
			{children}
		</IntlProvider>
	</RivIntlContext.Provider>
}
