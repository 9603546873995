import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import Bottom from '../../bottom/Bottom'
import { Form } from 'react-final-form'
import { StepperContext } from '../../StepperContext'
import RadioGroup, { RadioOption } from '../../../../components/field/radio/RadioGroup'
import PristineListener from '../../PristineListener'
import { BOOLEAN, TOOL_ATTRIBUTE_TYPE, TOOLS_TYPE } from '../../../../utils/enums'
import './toolsScreen.css'
import Input from '../../../../components/field/input/Input'
import NumberInput from '../../../../components/field/number-input/NumberInput'
import { gaugeTypes, picturesKey } from '../../../../utils/constants'
import Gauge from '../../../../components/field/gauge/Gauge'
import OutsideStateMultipleFileInput from '../../../../components/field/file-input/OutsideStateMultipleFileInput'
import { Typography } from '@material-ui/core'
import OutsideStatePictures from '../../../../components/field/pictures/OutsideStatePictures'
import arrayMutators from 'final-form-arrays'
import Textarea from '../../../../components/field/textarea/Textarea'
import { TranslationContext } from '../../../translation/TranslationContext'
import TranslatedMessage from '../../../translation/TranslatedMessage'

export type ToolsScreenProps = {
	toolType: TOOLS_TYPE
}

/**
 * Ecran "Cabine" ou "Equipement" dépend de {TOOLS_TYPE}
 * @param {TOOLS_TYPE} toolType
 * @returns {any}
 * @constructor
 */
const ToolsScreen = ({ toolType }: ToolsScreenProps) => {
	const { currentStepInfo, tools } = useContext(StepperContext)
	const { translations } = useContext(TranslationContext)
	const [intialValues, setIntialValues] = useState<ToolsForm>({ loadedPictures: [], deletedPicturesId: [] })

	const prefix = useMemo(() => `${toolType}-`, [toolType])

	useEffect(() => {
		if (currentStepInfo.data.values && currentStepInfo.data.values.tools) {
			let initialValues: ToolsForm = {}
			if (currentStepInfo.data.values.loadedPictures) {
				initialValues = { ...initialValues, loadedPictures: currentStepInfo.data.values.loadedPictures, deletedPicturesId: currentStepInfo.data.values.deletedPicturesId }
			} else {
				initialValues = { ...initialValues, loadedPictures: [], deletedPicturesId: [] }
			}
			if (currentStepInfo.data.values.cabinComment) {
				initialValues = { ...initialValues, cabinComment: currentStepInfo.data.values.cabinComment }
			}
			if (currentStepInfo.data.values.equipmentComment) {
				initialValues = { ...initialValues, equipmentComment: currentStepInfo.data.values.equipmentComment }
			}
			if (currentStepInfo.data.values.pictures) {
				initialValues = { ...initialValues, pictures: currentStepInfo.data.values.pictures }
			}
			currentStepInfo.data.values.tools.forEach((tool: SaveToolState) => {
				initialValues = { ...initialValues, [`${prefix}${tool.id}`]: tool.value }
			})
			setIntialValues(initialValues)
		}
	}, [currentStepInfo, prefix])

	const saveValues = (values: ToolsForm) => {
		const valuesToSend = Object.keys(values).filter(values => !picturesKey.includes(values)).reduce((acc: SaveToolState[], val) => {
			return [
				...acc,
				{
					id: val.replace(prefix, ''),
					value: values[val]
				}
			]
		}, [])
		currentStepInfo.data = {
			...currentStepInfo.data,
			values: { tools: valuesToSend, pictures: values.pictures, loadedPictures: values.loadedPictures, deletedPicturesId: values.deletedPicturesId, cabinComment: values.cabinComment, equipmentComment: values.equipmentComment }
		}
	}

	const validate = useCallback((values: ToolsForm) => {
		const errors: any = {}

		// Uniquement champ obligatoire et pour le radio l'option not_concerned
		tools && tools[toolType] && tools[toolType]
			.filter(tool => tool.mandatory || (!tool.mandatory && tool.rivAttributeModel.type === TOOL_ATTRIBUTE_TYPE.YES_NO))
			.forEach((tool) => {
				const fieldName = `${prefix}${tool.rivAttributeModel.id}`
				if (values[fieldName] === undefined) {
					errors[fieldName] = 'errors.form.required'
				}
			})

		if (toolType === TOOLS_TYPE.CABIN && (!values.pictures || !values.pictures.length) && !values.loadedPictures.length) {
			errors.pictures = ['errors.form.required']
		}

		return errors
	}, [tools, prefix, toolType])

	const createForm = useMemo(() => {
		const trueFalseOptions: RadioOption[] = [
			{
				label: <TranslatedMessage id="tools.radios.values.yes" defaultMessage="Oui" description="Yes" />,
				value: BOOLEAN.TRUE
			},
			{
				label: <TranslatedMessage id="tools.radios.values.no" defaultMessage="Non" description="No" />,
				value: BOOLEAN.FALSE
			}
		]

		return tools && tools[toolType] && tools[toolType].map((tool, index) => {
			const fieldName = `${prefix}${tool.rivAttributeModel.id}`
			const fieldLabel = `${translations[tool.rivAttributeModel.label] || tool.rivAttributeModel.label}`
			switch (tool.rivAttributeModel.type) {
				case TOOL_ATTRIBUTE_TYPE.TEXT:
					return <Input
						key={index}
						dynamicLabel={fieldLabel}
						optional={!tool.mandatory}
						name={fieldName}
						type="text"
					/>
				case TOOL_ATTRIBUTE_TYPE.TEXT_NUM:
					return <Input
						key={index}
						dynamicLabel={fieldLabel}
						optional={!tool.mandatory}
						name={fieldName}
						type="number"
					/>
				case TOOL_ATTRIBUTE_TYPE.NUMERIC:
					return <NumberInput
						key={index}
						dynamicLabel={translations[tool.rivAttributeModel.label]}
						name={fieldName}
					/>
				case TOOL_ATTRIBUTE_TYPE.YES_NO:
					const options = [...trueFalseOptions]
					if (!tool.mandatory) {
						options.push({
							label: <TranslatedMessage id="tools.radios.values.notConcerned" defaultMessage="Non concerné" description="Not concerned" />,
							value: BOOLEAN.NOT_CONCERNED
						})
					}
					return <RadioGroup
						key={index}
						dynamicLabel={translations[tool.rivAttributeModel.label]}
						name={fieldName}
						direction={!tool.mandatory ? 'row-tool' : 'row'}
						options={options}
					/>
				case TOOL_ATTRIBUTE_TYPE.SLIDER_4:
					return <Gauge
						key={index}
						dynamicLabel={fieldLabel}
						name={fieldName}
						type={gaugeTypes.medium}
						optional={!tool.mandatory}
					/>
				case TOOL_ATTRIBUTE_TYPE.SLIDER_8:
					return <Gauge
						key={index}
						dynamicLabel={fieldLabel}
						name={fieldName}
						type={gaugeTypes.big}
						optional={!tool.mandatory}
					/>
				default:
					return <div key={index} />
			}
		})
	}, [tools, prefix, toolType, translations])

	return (
		<Form
			onSubmit={saveValues}
			validate={validate}
			initialValues={intialValues}
			mutators={{
				...arrayMutators
			}}
			render={({ handleSubmit, invalid, values, pristine }) => (
				<>
					<PristineListener pristine={pristine} />
					<form onSubmit={handleSubmit} className="layout" autoComplete="off">
						<div className="left">
							{createForm}
						</div>
						<div className="right">
							<Textarea name={toolType === TOOLS_TYPE.EQUIPMENT ? 'equipmentComment' : 'cabinComment'} label="tools.comment" id="textarea" rows={4} />
							{values.loadedPictures && toolType === TOOLS_TYPE.CABIN && (
								<div className="flex-col">
									<Typography variant="h2" component="p">
										<TranslatedMessage id="form.pictures" defaultMessage="PHOTOS DE LA CABINE" description="Picture of the face" />
									</Typography>
									<OutsideStateMultipleFileInput
										name="pictures"
										label="outside_state.buttons.picture"
										multipleLabel="outside_state.buttons.another_picture"
										isMultiple={!!values.loadedPictures.length}
									/>
									{(!values.pictures || !values.pictures.length) && !values.loadedPictures.length && (
										<Typography variant="body1" component="span">
											<TranslatedMessage id="outside_state.info.picture" defaultMessage="Vous devez prendre au moins une photo" description="Info text" />
										</Typography>
									)}
									{!!values.loadedPictures.length && <OutsideStatePictures deletePicturesName="deletedPicturesId" loadedPicturesName="loadedPictures" />}
								</div>
							)}
						</div>
					</form>
					<Bottom disabled={invalid} onNext={() => saveValues(values)} onSave={() => saveValues(values)} />
				</>
			)}
		/>
	)
}

export default ToolsScreen
