import './stepper.css'
import { Typography, useMediaQuery } from '@material-ui/core'
import React, { Fragment, useContext, useState } from 'react'
import { DisabledChip, DoneChip, InProgressChip } from '../../components/chip/Chip'
import { minWidth } from '../../utils/constants'
import { StepperContext } from './StepperContext'
import { STEP_STATE } from '../../utils/enums'
import { Clear, Done } from '@material-ui/icons'
import BooleanWarningDialog from '../dialog/warning/BooleanWarningDialog'
import TranslatedMessage from '../translation/TranslatedMessage'

const StepperLayout = () => {
	const matchTablet = useMediaQuery(minWidth)
	const { activeStep, setActiveStep, stepsInfo, pristine } = useContext(StepperContext)
	const [openDialog, setOpenDialog] = useState(false)
	const [selectedStepIndex, setSelectedStepIndex] = useState(0)

	if (!setActiveStep) {
		return <></>
	}

	const peek = (index: number) => {
		if (stepsInfo[index].state === STEP_STATE.TODO || stepsInfo[index].state === STEP_STATE.DISABLED) {
			return
		} else if (!pristine && activeStep !== index) {
			setOpenDialog(true)
			setSelectedStepIndex(index)
		} else {
			setActiveStep(index)
		}
	}

	return (
		<div className="stepper" style={{ gridTemplateRows: `repeat(${stepsInfo.length}, 80px)` }}>
			{stepsInfo.map((step, i) => (
				<Fragment key={i}>
					<div
						className={`steplabel ${step.state === STEP_STATE.DONE && 'done'} ${step.state === STEP_STATE.IN_PROGRESS && 'progress'} ${step.state === STEP_STATE.DISABLED && 'disabled'} ${activeStep === i && 'active'}`}>
						<div className="content" onClick={() => peek(i)}>
							<div className="icon">{step.state === STEP_STATE.DONE ? <Done fontSize="small" /> : (step.state === STEP_STATE.DISABLED ? <Clear fontSize="small" /> : step.icon)}</div>
							<div>
								<Typography variant="caption" component="h3">
									<TranslatedMessage id="home.stepper.step.label" defaultMessage="Etape" description="Step Label" /> {i + 1}
								</Typography>
								<Typography className={`${step.state !== STEP_STATE.TODO && 'black'}`} variant="h2" component="h2">
									<TranslatedMessage id={step.title} defaultMessage="Etape Title" description="Step Label" />
								</Typography>
							</div>
							{step.state === STEP_STATE.DONE && (
								<DoneChip size="small" label={<TranslatedMessage id="home.stepper.done" defaultMessage="Complet" description="Completed chip" />} />
							)}
							{step.state === STEP_STATE.IN_PROGRESS && (
								<InProgressChip
									size="small"
									label={<TranslatedMessage id="home.stepper.in_progress" defaultMessage="En cours" description="In progress chip" />}
								/>
							)}
							{step.state === STEP_STATE.DISABLED && (
								<DisabledChip size="small" label={<TranslatedMessage id="home.stepper.disabled" defaultMessage="Non applicable" description="Disabled chip" />} />
							)}
						</div>
						{(matchTablet || activeStep !== i) && i + 1 !== stepsInfo.length && <div className="connector"></div>}
					</div>
					{activeStep === i && (
						<div className={`stepcontent ${step.state === STEP_STATE.DONE && 'done'}`} style={{ gridRowEnd: stepsInfo.length + 3 }}>
							{step.content}
						</div>
					)}
				</Fragment>
			))}
			<BooleanWarningDialog open={openDialog} setOpen={setOpenDialog} contentLabel="home.stepper.dialog.leave" onConfirm={() => setActiveStep(selectedStepIndex)} />
		</div>
	)
}

export default StepperLayout
