import { IconButton } from '@material-ui/core'
import { AddAPhoto, Close } from '@material-ui/icons'
import React, { useState } from 'react'
import { FieldArray } from 'react-final-form-arrays'
import DeleteImpactDialog from '../../../modules/dialog/delete-impact/DeleteImpactDialog'
import { MediumButton } from '../../buttons/Buttons'
import TranslatedMessage from '../../../modules/translation/TranslatedMessage'
import './file-input.css'

type Props = {
	name: string
	label: string
	multipleLabel: string
	isMultiple: boolean
}

const OutsideStateMultipleFileInput = ({ name, label, multipleLabel, isMultiple }: Props) => {
	const [openDialog, setOpenDialog] = useState(false)
	const [pictureIndex, setPictureIndex] = useState(0)

	return (
		<FieldArray name={name}>
			{({ fields }) => (
				<div className="img-input flex-col">
					<input
						accept="image/jpeg, image/png"
						type="file"
						capture="environment"
						id={name}
						onChange={({ target }) => {
							if (target && target.files && target.value && target.value.length > 0) {
								fields.unshift(target.files)
							}
						}} />
					<label htmlFor={name}>
						<MediumButton component="span" className="imgbutton">
							<AddAPhoto fontSize="small" />
							{label && (
								<span className="text">
                                    <TranslatedMessage id={(fields.value?.length || isMultiple) ? multipleLabel : label} defaultMessage="Prendre une photo" description="Take a picture" />
                                </span>
							)}
						</MediumButton>
					</label>

					{fields.value && fields.value.map((value, index) => {
							const images = Array.from(value)
							return images.map((file, j) => (
								<div key={j} className="img">
									<img alt="your img" src={URL.createObjectURL(file)} />
									<IconButton
										size="small"
										className="close"
										onClick={() => {
											setPictureIndex(index)
											setOpenDialog(true)
										}}
									>
										<Close />
									</IconButton>
								</div>
							))
						}
					)}
					<DeleteImpactDialog open={openDialog} setOpen={setOpenDialog} contentLabel="outside_state.dialog.delete_picture.content" onConfirm={() => fields.remove(pictureIndex)} />
				</div>
			)}
		</FieldArray>
	)
}

export default OutsideStateMultipleFileInput
