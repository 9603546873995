import { DialogProps, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { LargeButton } from '../../../components/buttons/Buttons'
import { RivDialog, RivDialogActions } from '../DialogStyles'
import TranslatedMessage from '../../translation/TranslatedMessage'

type Props = {
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
	contentLabel: string
	onConfirm: () => void
	onLeave?: () => void
} & DialogProps

const BooleanWarningDialog = ({ open, setOpen, contentLabel, onConfirm, onLeave }: Props) => {

	const [isLoading, setLoading] = useState(false)

	useEffect(() => {
		if (!open && isLoading) {
			setLoading(false)
		}

	}, [open, isLoading])

	const handleClose = () => {
		setOpen(false)
		setLoading(false)
		if (onLeave) {
			onLeave()
		}
	}

	const handleConfirm = () => {
		setLoading(true)
		onConfirm()
		setOpen(false)
	}

	return (
		<RivDialog open={open} onClose={handleClose} label="outside_state.dialog.warning.title">
			<Typography variant="body1" component="p">
				<TranslatedMessage id={contentLabel} defaultMessage="Action à risque" description="Warning text" />
			</Typography>
			<RivDialogActions>
				<LargeButton className="full-width" onClick={handleClose} variant="outlined">
					<TranslatedMessage id="search_vehicle.dialog.modify.cancel" defaultMessage="Annuler" description="Cancel button" />
				</LargeButton>
				<LargeButton className="full-width" onClick={() => !isLoading && open && handleConfirm()}>
					<TranslatedMessage id="search_vehicle.dialog.modify.confirm" defaultMessage="Continuer" description="Confirm button" />
				</LargeButton>
			</RivDialogActions>
		</RivDialog>
	)
}

export default BooleanWarningDialog
