import { useMediaQuery } from '@material-ui/core'
import React, { useContext, useState } from 'react'
import { LargeButton } from '../../../components/buttons/Buttons'
import { resetRivSteps } from '../../../services/save'
import { LOCAL_STORAGE_ITEM, minWidth } from '../../../utils/constants'
import { LANGUAGE_CODE, RIV_STEP_TYPE, STEP_STATE } from '../../../utils/enums'
import BooleanWarningDialog from '../../dialog/warning/BooleanWarningDialog'
import NetworkActionWarningDialog from '../../dialog/warning/NetworkActionWarningDialog'
import SimpleWarningDialog from '../../dialog/warning/SimpleWarningDialog'
import TranslatedMessage from '../../translation/TranslatedMessage'
import { StepperContext } from '../StepperContext'
import './bottom.css'
import { isOnline } from '../../../utils/platformUtils'

type Props = {
	disabled?: boolean
	onNext?: () => void
	onSave?: () => void
	onModify?: () => void
	disabledSave?: boolean
}

const Bottom = ({ disabled, onNext, onSave, onModify, disabledSave }: Props) => {
	const { activeStep, setActiveStep, stepsInfo, currentStepInfo, rivInfo, uploadRivInfos, uploadStateInfo, handleUploadRiv, setSnackbarInfo } = useContext(StepperContext)
	const matchTablet = useMediaQuery(minWidth)
	const [openSaveDialog, setOpenSaveDialog] = useState(false)
	const [openConflictDialog, setOpenConflictDialog] = useState(false)
	const [openSendDialog, setOpenSendDialog] = useState(false)
	const [wasDraft, setWasDraft] = useState<undefined | boolean>(undefined)
	const [openNetworkDialog, setOpenNetworkDialog] = useState(false)

	const handleNext = () => {
		if (onNext) {
			onNext()
		}
		if (currentStepInfo.state === STEP_STATE.DONE) {
			const lastActiveIndex = stepsInfo.findIndex((stepInfo) => stepInfo.state !== STEP_STATE.DONE && stepInfo.state !== STEP_STATE.DISABLED)
			if (lastActiveIndex !== -1 && setActiveStep) {
				setActiveStep(lastActiveIndex)
				stepsInfo[lastActiveIndex].state = STEP_STATE.IN_PROGRESS
			}
		} else {
			stepsInfo[activeStep].state = STEP_STATE.DONE
			if (activeStep === stepsInfo.length - 1) {
				setOpenSendDialog(true)
				return
			}
			if (stepsInfo[activeStep + 1].state === STEP_STATE.TODO) {
				stepsInfo[activeStep + 1].state = STEP_STATE.IN_PROGRESS
				if (setActiveStep) {
					setActiveStep((prev) => prev + 1)
				}
			} else if (stepsInfo[activeStep + 1].state === STEP_STATE.DISABLED) {
				// Si on est la derniere étape possible (la prochaine étant disabled
				if (activeStep === stepsInfo.length - 2) {
					setOpenSendDialog(true)
					return
				}
				// Le prochain est disabled donc on regarde celui d'apres n+2
				if (stepsInfo[activeStep + 2].state === STEP_STATE.TODO) {
					stepsInfo[activeStep + 2].state = STEP_STATE.IN_PROGRESS
					if (setActiveStep) {
						setActiveStep((prev) => prev + 2)
					}
				}
			}
		}
	}

	const handleSave = async () => {
		if (onSave) {
			onSave()
		}
		try {
			await handleSend(true)
			if (setSnackbarInfo) {
				setSnackbarInfo({ open: true, label: 'home.stepper.snackbar.save' })
			}
			rivInfo.draft = false
		} catch (error) {
		}
	}

	const handleSend = async (draft: boolean) => {
		let dataToSave: any = {
			pfCode: stepsInfo[0].data.selectedVehicle.codePf,
			categoryCode: stepsInfo[0].data.selectedVehicle.category,
			agenceCode: stepsInfo[0].data.selectedVehicle.agence,
			...rivInfo,
			draft
		}
		let medias: MediaInfo[] = []
		let deleteMediaIds: string[] = []
		let tools: SaveToolState[] = []
		stepsInfo.forEach((stepInfo) => {
			const objToSave: any = stepInfo.getSaveObject(stepInfo.data)
			if (objToSave.medias) {
				medias = [...medias, ...objToSave.medias]
			}
			if (objToSave.deleteMediaIds) {
				deleteMediaIds = [...deleteMediaIds, ...objToSave.deleteMediaIds]
			}
			if (objToSave.tools) {
				tools = [...tools, ...objToSave.tools]
			}
			dataToSave = {
				...dataToSave,
				...objToSave,
				equipmentComment: objToSave.equipmentComment || dataToSave.equipmentComment,
				cabinComment: objToSave.cabinComment || dataToSave.cabinComment
			}
		})
		dataToSave = {
			...dataToSave,
			medias: medias.map((media) => ({ mediaType: media.type })),
			deleteMediaIds,
			modificationDate: new Date().toISOString(),
			tools,
			languageCode: localStorage.getItem(LOCAL_STORAGE_ITEM.LANGUAGE) || LANGUAGE_CODE.FR
		}
		try {
			uploadRivInfos.push({
				uploadState: STEP_STATE.TODO,
				draft: rivInfo.draft,
				medias,
				dataToSave
			})
			rivInfo.type = RIV_STEP_TYPE.GO
			resetRiv()

			if (!uploadStateInfo.isUploading) {
				handleUploadRiv()
			}
		} catch (error) {
			if (!await isOnline()) {
				// Si on a pas le net
				setWasDraft(draft)
				setOpenNetworkDialog(true)
			} else {
				setOpenConflictDialog(true)
			}
			throw error
		}
	}

	const resetRiv = () => {
		if (setActiveStep) {
			resetRivSteps(stepsInfo)
			stepsInfo[0].state = STEP_STATE.IN_PROGRESS
			setActiveStep(0)
		}
	}

	const nextMsg = () => {
		if (activeStep === stepsInfo.length - 1) {
			return 'home.stepper.submit'
		}
		if (activeStep < 2 && rivInfo.type === RIV_STEP_TYPE.BACK) {
			if (matchTablet) {
				return 'home.stepper.next_tablet'
			}
			return 'home.stepper.next'
		}
		if (currentStepInfo.state === STEP_STATE.DONE) {
			return 'home.stepper.register'
		}
		if (matchTablet) {
			return 'home.stepper.next_tablet'
		}
		return 'home.stepper.next'
	}

	const handleDialogSend = async () => {
		try {
			await handleSend(false)
			if (setSnackbarInfo) {
				setSnackbarInfo({ open: true, label: 'home.stepper.snackbar.success' })
			}
			rivInfo.draft = false
		} catch (error) {
		}
	}

	return (
		<div className="actions">
			{activeStep !== 0 && activeStep !== stepsInfo.length - 1 && (
				<LargeButton onClick={() => setOpenSaveDialog(true)} variant="outlined" disabled={disabledSave}>
					<TranslatedMessage id={matchTablet ? 'home.stepper.save_tablet' : 'home.stepper.save'} defaultMessage="Sauvegarder" description="Save RIV" />
				</LargeButton>
			)}
			{onModify && currentStepInfo.state === STEP_STATE.DONE && (
				<LargeButton onClick={onModify} variant="outlined">
					<TranslatedMessage id="home.stepper.modify" defaultMessage="Modifier" description="Modify RIV" />
				</LargeButton>
			)}
			<LargeButton onClick={handleNext} disabled={disabled}>
				<TranslatedMessage id={nextMsg()} defaultMessage="Suivant" description="Next step" />
			</LargeButton>
			<BooleanWarningDialog open={openSaveDialog} setOpen={setOpenSaveDialog} onConfirm={handleSave} contentLabel="home.stepper.dialog.save" />
			<BooleanWarningDialog
				open={openSendDialog}
				setOpen={setOpenSendDialog}
				onConfirm={handleDialogSend}
				onLeave={() => {
					if (activeStep === stepsInfo.length - 1) {
						stepsInfo[activeStep].state = STEP_STATE.TODO
					}
				}}
				contentLabel="home.stepper.dialog.send"
			/>
			<SimpleWarningDialog
				open={openConflictDialog}
				setOpen={setOpenConflictDialog}
				onLeave={resetRiv}
				contentLabel="home.stepper.dialog.conflict"
			/>
			<NetworkActionWarningDialog
				open={openNetworkDialog}
				setOpen={setOpenNetworkDialog}
				onConfirm={wasDraft ? handleSave : handleDialogSend}
				onLeave={() => {
					if (activeStep === stepsInfo.length - 1) {
						stepsInfo[activeStep].state = STEP_STATE.TODO
					}
				}}
				contentLabel="dialog.network.warning.content"
			/>
		</div>
	)
}

export default Bottom
