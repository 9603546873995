import React from 'react'
import RivRadio from './Radio'
import TranslatedMessage from '../../../modules/translation/TranslatedMessage'

export type RadioOption = {
	value: string
	label: any
	img?: string
}

type Props = {
	name: string
	options: RadioOption[]
	onValueChange?: (newValue: string) => void
	direction: 'column' | 'row' | 'row-tool'
	disabled?: boolean
	label?: string
	dynamicLabel?: string
}

const RadioGroup = ({ name, options, direction, onValueChange, disabled, label, dynamicLabel }: Props) => {
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (onValueChange) {
			onValueChange(event.currentTarget.value)
		}
	}

	return (
		<div className="radiogroup">
			{(label || dynamicLabel) && <label>{dynamicLabel ?? <TranslatedMessage id={label} defaultMessage="Label" description="Input label" />}</label>}
			<div className={`grid ${direction}`}>
				{options.map((option, i) => (
					<RivRadio key={i} name={name} disabled={disabled} onChange={handleChange} value={option.value} img={option.img}>
						{option.label}
					</RivRadio>
				))}
			</div>
		</div>
	)
}

export default RadioGroup
