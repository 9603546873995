import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles'
import { colors } from '../utils/constants'

const theme = createMuiTheme({
	overrides: {},
	typography: {
		fontFamily: 'Inter',
		h1: {
			fontSize: 18,
			fontWeight: 'bold',
			color: colors.black
		},
		h2: {
			fontSize: 15,
			fontWeight: 600,
			color: colors.grey1,
			letterSpacing: 0.45
		},
		body1: {
			fontSize: 15,
			color: colors.grey1,
			letterSpacing: 0.45,
			lineHeight: '19px' // 19px because 19 does not translate to 19px
		},
		caption: {
			fontSize: 11,
			color: colors.grey1
		}
	},
	palette: {
		primary: {
			main: colors.primary
		},
		secondary: {
			main: colors.secondary,
			light: colors.secondaryLight
		},
		gauge: {
			separator: {
				main: colors.gaugeSeparator
			}
		},
		chip: {
			inProgress: {
				color: {
					main: colors.secondary
				},
				backgroundColor: {
					main: colors.secondaryLight
				}
			},
			done: {
				color: {
					main: colors.primary
				},
				backgroundColor: {
					main: colors.grey2
				}
			},
			disabled: {
				color: {
					main: colors.grey1
				},
				backgroundColor: {
					main: colors.grey2
				}
			}
		},
		dialog: {
			close: {
				color: {
					main: colors.grey1
				}
			}
		}

	}
})

export default theme
