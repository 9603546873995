import flatten from 'flat'

interface ModelTranslations {
	[key: string]: string
}

const target = {
	home: {
		title: {
			GENERAL: 'Saisir un RIV',
			ALLER: 'Saisir un RIV aller',
			RETOUR: 'Saisir un RIV retour'
		},
		stepper: {
			step: {
				label: 'Etape',
				search_vehicle: 'Chercher un véhicule',
				client_movement: 'Client et mouvement',
				driver: 'Chauffeur',
				cabin: 'Cabine',
				outside_state: 'Etat du véhicule',
				tools: 'Equipements',
				signatures: 'Signatures'
			},
			save: 'Brouillon',
			save_tablet: 'Sauvegarder un brouillon',
			register: 'Enregistrer',
			next: 'Suivant',
			next_tablet: 'Passer à l’étape suivante',
			done: 'Complet',
			modify: 'Modifier',
			in_progress: 'En cours',
			disabled: 'Non applicable',
			submit: 'Valider et envoyer le RIV',
			dialog: {
				leave: 'Les données n\'ont pas été enregistrées. Voulez-vous quitter cette étape ?',
				save: 'En sauvegardant un brouillon, vous allez quitter la saisie de ce RIV.',
				send: 'En envoyant le RIV, vous allez quitter la saisie. Ne fermez pas l\'application avant que toutes les images soient envoyées.',
				conflict: 'Un RIV plus récent existe déjà. Votre RIV actuel sera supprimé',
				synchronize: {
					title: 'Synchronisation en cours',
					content: 'Vous devez être connecté à internet pour synchroniser les RIV',
					todo: 'En attente...',
					in_progress: 'Envoi des photos ({num}/{total})',
					in_progress_riv: 'Envoi du RIV',
					done: 'Terminé',
					resynchronize: 'Resynchroniser',
					close: 'Fermer'
				}
			},
			snackbar: {
				success: 'RIV envoyé',
				save: 'RIV sauvegardé',
				error: 'Une erreur est survenue.'
			}
		},
		upload: 'Un RIV est en cours d\'envoi, veuillez ne pas fermer l\'application.'
	},
	search_vehicle: {
		radios: {
			labels: {
				search_by: 'rechercher par',
				found_two: 'DEUX VÉHICULES ONT ÉTÉ TROUVÉS'
			},
			search_by: {
				parc_number: 'Numéro de parc',
				numberplate: 'Immatriculation'
			}
		},
		inputs: {
			labels: {
				parc: 'Saisir le numéro de parc du véhicule',
				numberplate: 'Saisir l\'immatriculation du véhicule'
			}
		},
		info: {
			title: {
				go: 'Etablissement d’un RIV Aller',
				back: 'Etablissement d’un RIV Retour'
			},
			parc_number: 'N° de parc',
			numberplate: 'IMMATRICULATION',
			agence: 'AGENCE',
			category: 'CATÉGORIE',
			model: 'MODÈLE'
		},
		button_model: 'Sélectionnez un modèle',
		modify_model: 'Modifier le modèle',
		no_match: 'Aucun résultat trouvé',
		dialog: {
			model_selection: {
				title: 'Sélectionnez un modèle',
				select: 'Sélectionnez un modèle de véhicule pour effectuer le RIV',
				next: 'Continuer'
			},
			modify: {
				title: 'Attention !',
				content: 'Les données des étapes suivantes seront supprimées.',
				confirm: 'Confirmer',
				cancel: 'Annuler'
			},
            load_save: {
                title: {
					go: 'RIV Aller pré-enregistré',
					back: 'RIV Retour pré-enregistré'
				},
                content: 'Un RIV a déjà été pré-enregistré pour ce véhicule le {date} à {time}.',
				warning: 'Attention, dans le cas d’une nouvelle saisie les données pré-enregistrées seront perdues.',
				label: 'reprise du RIV',
                load: 'Reprendre le RIV pré-enregistré',
                delete: 'Effectuer une nouvelle saisie'
            },
			media_missing: 'Un RIV enregistré possède des médias manquants. Souhaitez_vous écraser ce RIV erroné ?',
			is_sending: 'Un RIV est en cours d\'envoi pour ce véhicule. Réessayer ultérieurement.'
		}
	},
	outside_state: {
		title: {
			select_face: 'SÉLECTIONNEZ UNE FACE',
			create_points: 'Créer des points sur l\'image',
			face_state: 'état de la face',
			face: {
				left: 'Face gauche',
				front: 'Face avant',
				right: 'Face droite',
				back: 'Face arrière',
				inside: 'Face arrière portes ouvertes'
			},
			face_picture: 'PHOTOS DE LA FACE'
		},
		info: {
			picture: 'Vous devez prendre au moins une photo',
			impact: 'Cliquez sur l\'image pour positionner des dégats'
		},
		radios: {
			damage: 'Dégâts',
			nothing: 'Rien à signaler'
		},
		buttons: {
			picture: 'Prendre une photo',
			another_picture: 'Prendre une autre photo'
		},
		comment: 'Commentaire',
		dialog: {
			create: {
				title: 'Ajouter un dégat',
				radio: {
					label: 'Type de dégât',
					scratch: 'Éraflure',
					blow: 'Coup',
					broken: 'Casse'
				}
			},
			delete_all: {
				title: 'Attention !',
				content: 'Voulez-vous supprimer les dégâts déjà saisis ?'
			},
			delete_one: {
				title: 'Attention !',
				content: 'Voulez-vous supprimer le dégât {num} sur cette face ?',
				content_previous_back: 'Le dégât {num} était présent sur l\'ancien RIV. Souhaitez-vous supprimer ce dégât ?'
			},
			delete_picture: {
				title: 'Attention !',
				content: 'Voulez-vous vraiment supprimer cette photo ?'
			},
			warning: {
				title: 'Attention !',
				content: 'Vous ne pouvez pas enregistrer plus de 10 dégats.'
			}
		}
	},
	client_movement: {
		radios: {
			labels: {
				movement_type: 'TYPE DU MOUVEMENT'
			}
		},
		inputs: {
			client_name: 'NOM DU CLIENT'
		}
	},
	driver: {
		radios: {
			driver_info: {
				label: 'informations chauffeur',
				manual: 'Remplir manuellement',
				none: 'Pas de chauffeur'
			}
		},
		inputs: {
			firstname: 'PRÉNOM DU CHAUFFEUR',
			lastname: 'NOM DU CHAUFFEUR',
			license_number: 'Numéro de permis',
			valid_date: 'date de validité',
			age: 'Conducteur âgé de 21 ans minimum',
			year: 'Permis de conduire en cours de validité depuis plus d\'un an',
			category: 'Permis correspondant au tonnage et à la catégorie du véhicule loué.'
		}
	},
	signatures: {
		radios: {
			labels: {
				send_mail_to_client: 'ENVOYER LE RIV AU CLIENT PAR MAIL ?',
				driver_is_present: 'CLIENT ET/OU CHAUFFEUR PRÉSENT ?'
			},
			values: {
				yes: 'Oui',
				no: 'Non'
			}
		},
		inputs: {
			client_email: 'ADRESSE E-MAIL',
			driver_firstname: 'PRÉNOM DU CLIENT ET/OU CHAUFFEUR',
			driver_lastname: 'NOM DU CLIENT ET/OU CHAUFFEUR',
			renter_firstname: 'PRÉNOM DU LOUEUR',
			renter_lastname: 'NOM DU LOUEUR'
		},
		checkbox: {
			driver_authorization: 'Le client et/ou chauffeur accepte que les informations relatives à son permis soient transmises.',
			driver_concern: 'Le client est informé du fonctionnement du véhicule et de ses particularités (gabarit, hauteur).'
		},
		signature: {
			driver: 'Signature du client et/ou chauffeur',
			renter: 'Signature du loueur'
		},
		reset: 'effacer'
	},
	tools: {
		radios: {
			values: {
				yes: 'Oui',
				no: 'Non',
				notConcerned: 'Non concerné'
			}
		},
		comment: 'COMMENTAIRE',
		notMandatory: '- FACULTATIF'
	},
	form: {
		gauges: {
			selected_value: 'Sélectionné : {value}'
		},
		pictures: 'PHOTOS DE LA CABINE'
	},
	errors: {
		form: {
			required: 'Champs requis',
			futureDate: 'Date future requise',
			pictureRequired: 'Photo requis',
			emailFormat: 'L\'adresse e-mail est incorrecte'
		},
		network: 'Une erreur technique est survenue'
	},
	serviceWorker: {
		addApp: {
			title: 'Ajouter l\'application RIV sur l\'écran d\'accueil',
			btn: 'Ajouter'
		},
		update: {
			title: 'Nouvelle version disponible',
			description: 'Une nouvelle version est disponible, vous devez mettre à jour votre version pour continuer d\'utiliser l\'application.',
			btn: 'Mettre à jour'
		}
	},
	cgu: {
		title: 'Conditions générales',
		checkbox: 'J\'accepte les conditions générales d\'utilisation',
		validate: 'Valider'
	},
	preferences: {
		title: 'Vos préférences',
		language: 'Sélectionner la langue',
		zone: 'Sélectionner la zone'
	},
	enums: {
		language: {
			fr: 'Français',
			en: 'Anglais',
			es: 'Espagnol',
			at: 'Autrichien',
			cz: 'Tchèque',
			de: 'Allemand',
			hu: 'Hongrois',
			it: 'Italien',
			pl: 'Polonais',
			pt: 'Portugais',
			us: 'Américain'
		},
		zone: {
			EU: 'Europe',
			UK: 'Royaume-Uni',
			US: 'États-Unis'
		},
		movement_type: {
			ald: 'Relais/ALD',
			cd: 'CD',
			cdm: 'CDM',
			ld: 'LD',
			at_ext: 'Atelier Extérieur',
			trans: 'Transit Agence',
			other: 'Autre'
		},
		movement_comment: {
			ald: 'NUMÉRO DE PARC',
			other: 'PRÉCISEZ'
		}
	},
	dialog: {
		network: {
			warning: {
				title: 'Attention !',
				content: 'Une connexion internet est nécessaire pour envoyer les données.',
				modify: {
					cancel: 'Annuler',
					retry: 'Réessayer'
				}
			}
		}
	}
}

export default flatten<typeof target, ModelTranslations>(target)
