import React from 'react'
import ReactDOM from 'react-dom'
import { ConnectedRouter, connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { intlReducer, Provider } from 'react-intl-redux'
import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import App from './containers/App'
import { MuiThemeProvider } from '@material-ui/core'
import theme from './config/theme'
import { StepperProvider } from './modules/stepper/StepperContext'
import './index.css'
import './assets/fonts/Inter/import.css'
import ServiceWorkerWrapper from './modules/serviceWorker/ServiceWorkerWrapper'
import { IntlProviderWrapper } from './config/messages/RivIntlContext'
import { TranslationProvider } from './modules/translation/TranslationContext'
import { MovementProvider } from './movement/MovementContext'

// Création du router
const history = createBrowserHistory()

const rootReducer = combineReducers({
	router: connectRouter(history),
	intl: intlReducer
})

const reactInit = () => {
	// Initialisation de tous les enhancers utilisés avec Redux
	const enhancers = [applyMiddleware(routerMiddleware(history))]

	// Création du store de redux
	let store = createStore(rootReducer, compose(...enhancers))

	ReactDOM.render(
		<React.StrictMode>
			<MuiThemeProvider theme={theme}>
				<Provider store={store}>
					<ConnectedRouter history={history}>
						<IntlProviderWrapper>
							<ServiceWorkerWrapper>
								<TranslationProvider>
									<MovementProvider>
										<StepperProvider>
											<App />
										</StepperProvider>
									</MovementProvider>
								</TranslationProvider>
							</ServiceWorkerWrapper>
						</IntlProviderWrapper>
					</ConnectedRouter>
				</Provider>
			</MuiThemeProvider>
		</React.StrictMode>,
		document.getElementById('root')
	)
}

reactInit()
