import { IconButton } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import React, { useState } from 'react'
import { FieldArray } from 'react-final-form-arrays'
import DeleteImpactDialog from '../../../modules/dialog/delete-impact/DeleteImpactDialog'

type Props = {
    loadedPicturesName: string
    deletePicturesName: string
}

const OutsideStatePictures = ({ deletePicturesName, loadedPicturesName }: Props) => {
    const [openDialog, setOpenDialog] = useState(false)
    const [pictureIndex, setPictureIndex] = useState(0)

    return (
        <FieldArray name={loadedPicturesName}>
            {({ fields }) => (
                <div className="img-input flex-col">
                    {fields.value.map((picture: MediaResponse, index) => (
                        <div className="img" key={index}>
                            <img src={picture.url} alt="Face" />
                            <IconButton
                                size="small"
                                className="close"
                                onClick={() => {
                                    setPictureIndex(index)
                                    setOpenDialog(true)
                                }}
                            >
                                <Close />
                            </IconButton>
                        </div>
                    ))}
                    <FieldArray name={deletePicturesName}>
                        {({ fields: deleteFields }) => (
                            <DeleteImpactDialog
                                open={openDialog}
                                setOpen={setOpenDialog}
                                contentLabel="outside_state.dialog.delete_picture.content"
                                onConfirm={() => {
                                    deleteFields.push(fields.value[pictureIndex].id)
                                    fields.remove(pictureIndex)
                                }}
                            />
                        )}
                    </FieldArray>
                </div>
            )}
        </FieldArray>
    )
}

export default OutsideStatePictures
